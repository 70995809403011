/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RecordStatusChangeStatus {
    Initiated = 'INITIATED',
    Approved = 'APPROVED',
    Denied = 'DENIED',
    Cancelled = 'CANCELLED'
}

export function RecordStatusChangeStatusFromJSON(json: any): RecordStatusChangeStatus {
    return RecordStatusChangeStatusFromJSONTyped(json, false);
}

export function RecordStatusChangeStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordStatusChangeStatus {
    return json as RecordStatusChangeStatus;
}

export function RecordStatusChangeStatusToJSON(value?: RecordStatusChangeStatus | null): any {
    return value as any;
}

