/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CancelChangeReason } from './CancelChangeReason';
import {
    CancelChangeReasonFromJSON,
    CancelChangeReasonFromJSONTyped,
    CancelChangeReasonToJSON,
} from './CancelChangeReason';
import type { CloseChangeReason } from './CloseChangeReason';
import {
    CloseChangeReasonFromJSON,
    CloseChangeReasonFromJSONTyped,
    CloseChangeReasonToJSON,
} from './CloseChangeReason';
import type { RecordStatusChangeStatus } from './RecordStatusChangeStatus';
import {
    RecordStatusChangeStatusFromJSON,
    RecordStatusChangeStatusFromJSONTyped,
    RecordStatusChangeStatusToJSON,
} from './RecordStatusChangeStatus';
import type { RecordStatusChangeType } from './RecordStatusChangeType';
import {
    RecordStatusChangeTypeFromJSON,
    RecordStatusChangeTypeFromJSONTyped,
    RecordStatusChangeTypeToJSON,
} from './RecordStatusChangeType';

/**
 * 
 * @export
 * @interface RecordStatusChange
 */
export interface RecordStatusChange {
    /**
     * 
     * @type {string}
     * @memberof RecordStatusChange
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordStatusChange
     */
    recordId?: string;
    /**
     * 
     * @type {RecordStatusChangeType}
     * @memberof RecordStatusChange
     */
    type?: RecordStatusChangeType;
    /**
     * 
     * @type {RecordStatusChangeStatus}
     * @memberof RecordStatusChange
     */
    status?: RecordStatusChangeStatus;
    /**
     * 
     * @type {string}
     * @memberof RecordStatusChange
     */
    originatorUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordStatusChange
     */
    originatorComments?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordStatusChange
     */
    evaluatorUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordStatusChange
     */
    evaluatorComments?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RecordStatusChange
     */
    requestTimestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RecordStatusChange
     */
    evaluationTimestamp?: Date | null;
    /**
     * 
     * @type {CloseChangeReason}
     * @memberof RecordStatusChange
     */
    closeReason?: CloseChangeReason;
    /**
     * 
     * @type {CancelChangeReason}
     * @memberof RecordStatusChange
     */
    cancelReason?: CancelChangeReason;
}

export function RecordStatusChangeFromJSON(json: any): RecordStatusChange {
    return RecordStatusChangeFromJSONTyped(json, false);
}

export function RecordStatusChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordStatusChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'recordId': !exists(json, 'recordId') ? undefined : json['recordId'],
        'type': !exists(json, 'type') ? undefined : RecordStatusChangeTypeFromJSON(json['type']),
        'status': !exists(json, 'status') ? undefined : RecordStatusChangeStatusFromJSON(json['status']),
        'originatorUserId': !exists(json, 'originatorUserId') ? undefined : json['originatorUserId'],
        'originatorComments': !exists(json, 'originatorComments') ? undefined : json['originatorComments'],
        'evaluatorUserId': !exists(json, 'evaluatorUserId') ? undefined : json['evaluatorUserId'],
        'evaluatorComments': !exists(json, 'evaluatorComments') ? undefined : json['evaluatorComments'],
        'requestTimestamp': !exists(json, 'requestTimestamp') || json['requestTimestamp'] === '' ? undefined : new Date(json['requestTimestamp']),
        'evaluationTimestamp': !exists(json, 'evaluationTimestamp') || json['evaluationTimestamp'] === '' ? undefined : json['evaluationTimestamp'] === null ? null : new Date(json['evaluationTimestamp']),
        'closeReason': !exists(json, 'closeReason') ? undefined : CloseChangeReasonFromJSON(json['closeReason']),
        'cancelReason': !exists(json, 'cancelReason') ? undefined : CancelChangeReasonFromJSON(json['cancelReason']),
    };
}

export function RecordStatusChangeToJSON(value?: RecordStatusChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'recordId': value.recordId,
        'type': RecordStatusChangeTypeToJSON(value.type),
        'status': RecordStatusChangeStatusToJSON(value.status),
        'originatorUserId': value.originatorUserId,
        'originatorComments': value.originatorComments,
        'evaluatorUserId': value.evaluatorUserId,
        'evaluatorComments': value.evaluatorComments,
        'requestTimestamp': value.requestTimestamp === undefined ? undefined : value.requestTimestamp.toISOString(),
        'evaluationTimestamp': value.evaluationTimestamp === undefined ? undefined : value.evaluationTimestamp === null ? null : value.evaluationTimestamp.toISOString(),
        'closeReason': CloseChangeReasonToJSON(value.closeReason),
        'cancelReason': CancelChangeReasonToJSON(value.cancelReason),
    };
}

