import { applicationConfig } from "@mc/common";

// The configuration of the REST API client.
export type ApiConfiguration = {
    // The access token to use for authentication.
    accessToken: string | undefined;

    // The base API path to use.
    basePath: string;

    // The headers to send with each request.
    headers: Record<string, string>;
};

export const apiConfiguration: ApiConfiguration = {
    // NOTE: This is set by the authenticationContext, not here, otherwise we end
    // up with a circular dependency.
    accessToken: undefined,
    get basePath() {
        const basePath = applicationConfig.api?.rest?.urls?.default ?? "";
        if (!basePath) {
            // NOTE: We can't provide a valid configuration without
            // a base API path.
            throw new Error("No API base path is configured.");
        }
        return basePath;
    },
    get headers() {
        if (typeof window !== "undefined") {
            const localFlags = localStorage?.getItem("featureFlags");
            const result: Record<string, string> = {
                "Mc-Application-Id": window.location.pathname.split("/")[1],
            };

            // FIXME: This isn't a good long-term solution. We aren't checking to see if we're in
            // dev/stage/prod. This solution came from Architecture but with the caveat that we
            // put a FIXME in here to fix in the long-term.
            if (localFlags) {
                result["X-Mc-Flag-Overrides"] = localFlags;
            }
            return result;
        }
        return {};
    },
};
