/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { FormElementType } from './FormElementType';
import {
    FormElementTypeFromJSON,
    FormElementTypeFromJSONTyped,
    FormElementTypeToJSON,
} from './FormElementType';
import type { RecordLinkAllOf } from './RecordLinkAllOf';
import {
    RecordLinkAllOfFromJSON,
    RecordLinkAllOfFromJSONTyped,
    RecordLinkAllOfToJSON,
} from './RecordLinkAllOf';

/**
 * 
 * @export
 * @interface RecordLink
 */
export interface RecordLink extends FormElement {
    /**
     * 
     * @type {boolean}
     * @memberof RecordLink
     */
    newEvent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RecordLink
     */
    openEvents?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RecordLink
     */
    closedEvents?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RecordLink
     */
    showHintText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RecordLink
     */
    hintText?: string;
}

export function RecordLinkFromJSON(json: any): RecordLink {
    return RecordLinkFromJSONTyped(json, false);
}

export function RecordLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormElementFromJSONTyped(json, ignoreDiscriminator),
        'newEvent': !exists(json, 'newEvent') ? undefined : json['newEvent'],
        'openEvents': !exists(json, 'openEvents') ? undefined : json['openEvents'],
        'closedEvents': !exists(json, 'closedEvents') ? undefined : json['closedEvents'],
        'showHintText': !exists(json, 'showHintText') ? undefined : json['showHintText'],
        'hintText': !exists(json, 'hintText') ? undefined : json['hintText'],
    };
}

export function RecordLinkToJSON(value?: RecordLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormElementToJSON(value),
        'newEvent': value.newEvent,
        'openEvents': value.openEvents,
        'closedEvents': value.closedEvents,
        'showHintText': value.showHintText,
        'hintText': value.hintText,
    };
}

