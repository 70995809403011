/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActionItem } from './ActionItem';
import {
    ActionItemFromJSON,
    ActionItemFromJSONTyped,
    ActionItemToJSON,
} from './ActionItem';
import type { Attachment } from './Attachment';
import {
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
} from './Attachment';
import type { CQxDocumentLink } from './CQxDocumentLink';
import {
    CQxDocumentLinkFromJSON,
    CQxDocumentLinkFromJSONTyped,
    CQxDocumentLinkToJSON,
} from './CQxDocumentLink';
import type { Correction } from './Correction';
import {
    CorrectionFromJSON,
    CorrectionFromJSONTyped,
    CorrectionToJSON,
} from './Correction';
import type { DirectCorrection } from './DirectCorrection';
import {
    DirectCorrectionFromJSON,
    DirectCorrectionFromJSONTyped,
    DirectCorrectionToJSON,
} from './DirectCorrection';
import type { DueDateExtension } from './DueDateExtension';
import {
    DueDateExtensionFromJSON,
    DueDateExtensionFromJSONTyped,
    DueDateExtensionToJSON,
} from './DueDateExtension';
import type { ElementData } from './ElementData';
import {
    ElementDataFromJSON,
    ElementDataFromJSONTyped,
    ElementDataToJSON,
} from './ElementData';
import type { ExecutionPlanElement } from './ExecutionPlanElement';
import {
    ExecutionPlanElementFromJSON,
    ExecutionPlanElementFromJSONTyped,
    ExecutionPlanElementToJSON,
} from './ExecutionPlanElement';
import type { ExecutionPlanSection } from './ExecutionPlanSection';
import {
    ExecutionPlanSectionFromJSON,
    ExecutionPlanSectionFromJSONTyped,
    ExecutionPlanSectionToJSON,
} from './ExecutionPlanSection';
import type { Node } from './Node';
import {
    NodeFromJSON,
    NodeFromJSONTyped,
    NodeToJSON,
} from './Node';
import type { Record } from './Record';
import {
    RecordFromJSON,
    RecordFromJSONTyped,
    RecordToJSON,
} from './Record';
import type { RecordLink } from './RecordLink';
import {
    RecordLinkFromJSON,
    RecordLinkFromJSONTyped,
    RecordLinkToJSON,
} from './RecordLink';
import type { RecordStatusChange } from './RecordStatusChange';
import {
    RecordStatusChangeFromJSON,
    RecordStatusChangeFromJSONTyped,
    RecordStatusChangeToJSON,
} from './RecordStatusChange';
import type { Signature } from './Signature';
import {
    SignatureFromJSON,
    SignatureFromJSONTyped,
    SignatureToJSON,
} from './Signature';
import type { Task } from './Task';
import {
    TaskFromJSON,
    TaskFromJSONTyped,
    TaskToJSON,
} from './Task';

/**
 * 
 * @export
 * @interface RecordDetailViewV2
 */
export interface RecordDetailViewV2 {
    /**
     * 
     * @type {Array<ExecutionPlanSection>}
     * @memberof RecordDetailViewV2
     */
    sections?: Array<ExecutionPlanSection>;
    /**
     * 
     * @type {Array<ExecutionPlanElement>}
     * @memberof RecordDetailViewV2
     */
    elements?: Array<ExecutionPlanElement>;
    /**
     * 
     * @type {Array<ElementData>}
     * @memberof RecordDetailViewV2
     */
    elementDatas?: Array<ElementData>;
    /**
     * 
     * @type {Array<Node>}
     * @memberof RecordDetailViewV2
     */
    nodes?: Array<Node>;
    /**
     * 
     * @type {Array<Correction>}
     * @memberof RecordDetailViewV2
     */
    corrections?: Array<Correction>;
    /**
     * 
     * @type {Array<DirectCorrection>}
     * @memberof RecordDetailViewV2
     */
    directCorrections?: Array<DirectCorrection>;
    /**
     * 
     * @type {Array<Task>}
     * @memberof RecordDetailViewV2
     */
    tasks?: Array<Task>;
    /**
     * 
     * @type {Record}
     * @memberof RecordDetailViewV2
     */
    record?: Record;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof RecordDetailViewV2
     */
    attachments?: Array<Attachment>;
    /**
     * 
     * @type {Array<Signature>}
     * @memberof RecordDetailViewV2
     */
    signatures?: Array<Signature>;
    /**
     * 
     * @type {Array<RecordLink>}
     * @memberof RecordDetailViewV2
     */
    recordLinks?: Array<RecordLink>;
    /**
     * 
     * @type {Array<CQxDocumentLink>}
     * @memberof RecordDetailViewV2
     */
    cqxDocumentLinks?: Array<CQxDocumentLink>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordDetailViewV2
     */
    validSites?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RecordDetailViewV2
     */
    allSitesValid?: boolean;
    /**
     * 
     * @type {Array<DueDateExtension>}
     * @memberof RecordDetailViewV2
     */
    dueDateExtensions?: Array<DueDateExtension>;
    /**
     * 
     * @type {Array<ActionItem>}
     * @memberof RecordDetailViewV2
     */
    actionItems?: Array<ActionItem>;
    /**
     * 
     * @type {Array<RecordStatusChange>}
     * @memberof RecordDetailViewV2
     */
    recordStatusChanges?: Array<RecordStatusChange>;
}

export function RecordDetailViewV2FromJSON(json: any): RecordDetailViewV2 {
    return RecordDetailViewV2FromJSONTyped(json, false);
}

export function RecordDetailViewV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordDetailViewV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sections': !exists(json, 'sections') ? undefined : (json['sections'] as Array<ExecutionPlanSection>).map(ExecutionPlanSectionFromJSON),
        'elements': !exists(json, 'elements') ? undefined : (json['elements'] as Array<ExecutionPlanElement>).map(ExecutionPlanElementFromJSON),
        'elementDatas': !exists(json, 'elementDatas') ? undefined : (json['elementDatas'] as Array<ElementData>).map(ElementDataFromJSON),
        'nodes': !exists(json, 'nodes') ? undefined : (json['nodes'] as Array<Node>).map(NodeFromJSON),
        'corrections': !exists(json, 'corrections') ? undefined : (json['corrections'] as Array<Correction>).map(CorrectionFromJSON),
        'directCorrections': !exists(json, 'directCorrections') ? undefined : (json['directCorrections'] as Array<DirectCorrection>).map(DirectCorrectionFromJSON),
        'tasks': !exists(json, 'tasks') ? undefined : (json['tasks'] as Array<Task>).map(TaskFromJSON),
        'record': !exists(json, 'record') ? undefined : RecordFromJSON(json['record']),
        'attachments': !exists(json, 'attachments') ? undefined : (json['attachments'] as Array<Attachment>).map(AttachmentFromJSON),
        'signatures': !exists(json, 'signatures') ? undefined : (json['signatures'] as Array<Signature>).map(SignatureFromJSON),
        'recordLinks': !exists(json, 'recordLinks') ? undefined : (json['recordLinks'] as Array<RecordLink>).map(RecordLinkFromJSON),
        'cqxDocumentLinks': !exists(json, 'cqxDocumentLinks') ? undefined : (json['cqxDocumentLinks'] as Array<CQxDocumentLink>).map(CQxDocumentLinkFromJSON),
        'validSites': !exists(json, 'validSites') ? undefined : (json['validSites'] as Array<string>),
        'allSitesValid': !exists(json, 'allSitesValid') ? undefined : json['allSitesValid'],
        'dueDateExtensions': !exists(json, 'dueDateExtensions') ? undefined : (json['dueDateExtensions'] as Array<DueDateExtension>).map(DueDateExtensionFromJSON),
        'actionItems': !exists(json, 'actionItems') ? undefined : (json['actionItems'] as Array<ActionItem>).map(ActionItemFromJSON),
        'recordStatusChanges': !exists(json, 'recordStatusChanges') ? undefined : (json['recordStatusChanges'] as Array<RecordStatusChange>).map(RecordStatusChangeFromJSON),
    };
}

export function RecordDetailViewV2ToJSON(value?: RecordDetailViewV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sections': value.sections === undefined ? undefined : (value.sections as Array<ExecutionPlanSection>).map(ExecutionPlanSectionToJSON),
        'elements': value.elements === undefined ? undefined : (value.elements as Array<ExecutionPlanElement>).map(ExecutionPlanElementToJSON),
        'elementDatas': value.elementDatas === undefined ? undefined : (value.elementDatas as Array<ElementData>).map(ElementDataToJSON),
        'nodes': value.nodes === undefined ? undefined : (value.nodes as Array<Node>).map(NodeToJSON),
        'corrections': value.corrections === undefined ? undefined : (value.corrections as Array<Correction>).map(CorrectionToJSON),
        'directCorrections': value.directCorrections === undefined ? undefined : (value.directCorrections as Array<DirectCorrection>).map(DirectCorrectionToJSON),
        'tasks': value.tasks === undefined ? undefined : (value.tasks as Array<Task>).map(TaskToJSON),
        'record': RecordToJSON(value.record),
        'attachments': value.attachments === undefined ? undefined : (value.attachments as Array<Attachment>).map(AttachmentToJSON),
        'signatures': value.signatures === undefined ? undefined : (value.signatures as Array<Signature>).map(SignatureToJSON),
        'recordLinks': value.recordLinks === undefined ? undefined : (value.recordLinks as Array<RecordLink>).map(RecordLinkToJSON),
        'cqxDocumentLinks': value.cqxDocumentLinks === undefined ? undefined : (value.cqxDocumentLinks as Array<CQxDocumentLink>).map(CQxDocumentLinkToJSON),
        'validSites': value.validSites === undefined ? undefined : (value.validSites as Array<string>),
        'allSitesValid': value.allSitesValid,
        'dueDateExtensions': value.dueDateExtensions === undefined ? undefined : (value.dueDateExtensions as Array<DueDateExtension>).map(DueDateExtensionToJSON),
        'actionItems': value.actionItems === undefined ? undefined : (value.actionItems as Array<ActionItem>).map(ActionItemToJSON),
        'recordStatusChanges': value.recordStatusChanges === undefined ? undefined : (value.recordStatusChanges as Array<RecordStatusChange>).map(RecordStatusChangeToJSON),
    };
}

