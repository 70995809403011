/* eslint-disable @typescript-eslint/no-unused-vars */
import { AuthenticationContextEventType } from "@mc/auth";
import { loadApplicationConfiguration } from "@mc/common";
import flagsmith from "flagsmith";
import { registerApplication, start } from "single-spa";
import { constructApplications, constructLayoutEngine, constructRoutes } from "single-spa-layout";
import "../i18n/i18n";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const appMap: { [key: string]: () => Promise<any> } = {
    "@mc/adocs-web": () => import("@mc/adocs-web"),
    "@mc/amx-web": () => import("@mc/amx-web"),
    "@mc/aqem-web": () => import("@mc/aqem-web"),
    "@mc/auth-web": () => import("@mc/auth-web"),
    "@mc/homepage-web": () => import("@mc/homepage-web"),
    "@mc/logbooks-web": () => import("@mc/logbooks-web"),
    "@mc/mcadmin-web": () => import("@mc/mcadmin-web"),
};
const root = document.querySelector("#single-spa-layout") as Element;
const routes = constructRoutes(root);
const applications = constructApplications({
    loadApp: ({ name }) => appMap[name](),
    routes,
});

const layoutEngine = constructLayoutEngine({
    active: false,
    applications,
    routes,
});

const applicationConfig = await loadApplicationConfiguration();

await flagsmith.init({
    cacheFlags: true,
    cacheOptions: {
        skipAPI: true,
        ttl: 5 * 60 * 1000,
    },
    environmentID: applicationConfig?.featureFlag?.key,
});
let nonce = undefined;
const scripts = document.getElementsByTagName("script");
for (let i = 0, length = scripts.length; i < length; i++) {
    nonce = scripts[i].nonce;
    if (nonce) {
        break;
    }
}
const customProps = {
    nonce,
};

applications.forEach((app) => {
    registerApplication({
        ...app,
        customProps,
    });
});

layoutEngine.activate();
window.addEventListener("single-spa:app-change", (evt) => {
    window.dispatchEvent(new CustomEvent(AuthenticationContextEventType.Changed));
});
start();
