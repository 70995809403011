/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationResult } from './PaginationResult';
import {
    PaginationResultFromJSON,
    PaginationResultFromJSONTyped,
    PaginationResultToJSON,
} from './PaginationResult';
import type { PartialRecordDetailView } from './PartialRecordDetailView';
import {
    PartialRecordDetailViewFromJSON,
    PartialRecordDetailViewFromJSONTyped,
    PartialRecordDetailViewToJSON,
} from './PartialRecordDetailView';

/**
 * 
 * @export
 * @interface RecordCollectionResponse
 */
export interface RecordCollectionResponse {
    /**
     * 
     * @type {PaginationResult}
     * @memberof RecordCollectionResponse
     */
    pagination?: PaginationResult;
    /**
     * 
     * @type {Array<PartialRecordDetailView>}
     * @memberof RecordCollectionResponse
     */
    recordCollection?: Array<PartialRecordDetailView>;
}

export function RecordCollectionResponseFromJSON(json: any): RecordCollectionResponse {
    return RecordCollectionResponseFromJSONTyped(json, false);
}

export function RecordCollectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordCollectionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': !exists(json, 'pagination') ? undefined : PaginationResultFromJSON(json['pagination']),
        'recordCollection': !exists(json, 'recordCollection') ? undefined : (json['recordCollection'] as Array<PartialRecordDetailView>).map(PartialRecordDetailViewFromJSON),
    };
}

export function RecordCollectionResponseToJSON(value?: RecordCollectionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationResultToJSON(value.pagination),
        'recordCollection': value.recordCollection === undefined ? undefined : (value.recordCollection as Array<PartialRecordDetailView>).map(PartialRecordDetailViewToJSON),
    };
}

