import { apiConfiguration } from "@mc/api";
import { getAuthLocalStorageValue } from "@mc/auth";
import { applicationConfig, ApplicationConfigEventType, MOCK_ACCESS_TOKEN_STORAGE_KEY } from "@mc/common";
import { AuthState, OktaAuth, OktaAuthOptions, toRelativeUrl } from "@okta/okta-auth-js";
import jwtDecode, { JwtPayload } from "jwt-decode";

type AuthenticationContext = {
    accessGroups: Map<string, string[]>;
    authClient: OktaAuth | null;
    isAuthenticated: boolean;
};
export enum AuthenticationContextEventType {
    /** Emitted any time the authenticationContext has been updated for whatever reason. */
    Changed = "mc:auth:changed",
}

/**
 * Contains information about the current state of authorization. The object will be
 * directly mutated as values change, so it should not be cached. Instead, listen for
 * changes to the context using the `AuthenticationContextEventType.Changed` event on
 * the `window` object.
 */
export const authenticationContext: AuthenticationContext = {
    accessGroups: new Map(),
    authClient: null,
    isAuthenticated: false,
};

window.addEventListener(ApplicationConfigEventType.Changed, updateApplicationContext);

function updateApplicationContext() {
    // Unsubscribe from the previous auth client
    authenticationContext.authClient?.authStateManager.unsubscribe();
    const mcToken = getAuthLocalStorageValue("mcToken");

    const shouldBeMocked = !!applicationConfig.authentication?.shouldBeMocked;
    if (!shouldBeMocked) {
        const oktaOptions: OktaAuthOptions = {
            ...applicationConfig.authentication,
            restoreOriginalUri: (_oktaAuth: OktaAuth, originalUri: string) => {
                const uri = toRelativeUrl(originalUri || "/", window.location.origin);
                window.location.replace(uri);
            },
        };

        const client = new OktaAuth(oktaOptions);
        client.authStateManager.subscribe((authState: AuthState) => {
            if (authState.isAuthenticated !== undefined) {
                authenticationContext.isAuthenticated = authState.isAuthenticated;
            }

            if (mcToken) {
                apiConfiguration.accessToken = mcToken;
                const currentTime = +new Date() / 1000;
                const decodedToken = jwtDecode<JwtPayload & { groups?: string[] }>(mcToken);

                authenticationContext.accessGroups.set(mcToken, decodedToken?.groups || []);

                if ((decodedToken?.exp ?? 0) > currentTime) {
                    authenticationContext.isAuthenticated = true;
                }
            }

            if (authState.accessToken && !mcToken) {
                apiConfiguration.accessToken = authState.accessToken.accessToken;

                const decoded = client.token.decode(authState.accessToken.accessToken);
                const groups = decoded?.payload?.grp as unknown as string[];
                if (groups) {
                    authenticationContext.accessGroups.set(authState.accessToken.accessToken, groups);
                }
            }
            window.dispatchEvent(new CustomEvent(AuthenticationContextEventType.Changed));
        });
        authenticationContext.authClient = client;
        client.start();
    } else {
        const client = new OktaAuth({
            issuer: "https://mclabstest.oktapreview.com/oauth2/default",
        });
        authenticationContext.authClient = client;
        authenticationContext.isAuthenticated = true;
        const mockAccessToken = window?.localStorage?.getItem(MOCK_ACCESS_TOKEN_STORAGE_KEY) ?? undefined;
        if (mockAccessToken && client) {
            apiConfiguration.accessToken = mockAccessToken;
            const decoded = client.token.decode(mockAccessToken);
            authenticationContext.accessGroups.set(
                mockAccessToken,
                (decoded?.payload?.grp as unknown as string[]) ?? [],
            );
        }
    }
    window.dispatchEvent(new CustomEvent(AuthenticationContextEventType.Changed));
}
