/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CQxDocumentLink,
    CQxDocumentLinkFromJSON,
    CQxDocumentLinkToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    LinkCQxDocumentRequest,
    LinkCQxDocumentRequestFromJSON,
    LinkCQxDocumentRequestToJSON,
    LinkRecordRequest,
    LinkRecordRequestFromJSON,
    LinkRecordRequestToJSON,
    Record,
    RecordFromJSON,
    RecordToJSON,
    RecordLink,
    RecordLinkFromJSON,
    RecordLinkToJSON,
    RecordLinkResponse,
    RecordLinkResponseFromJSON,
    RecordLinkResponseToJSON,
    UnlinkCQxDocumentRequest,
    UnlinkCQxDocumentRequestFromJSON,
    UnlinkCQxDocumentRequestToJSON,
} from '../models';

export interface GetLinkedRecordsByRecordIdRequest {
    fromRecordId: string;
}

export interface GetRecordLinkByIdRequest {
    linkId: string;
}

export interface GetRecordLinkResponseByFromElementIdRequest {
    fromElementId: string;
}

export interface GetRecordLinkResponseByRecordIdRequest {
    fromRecordId: string;
    bidirectional?: boolean;
}

export interface GetRecordsLinkedByFromElementIdRequest {
    fromElementId: string;
}

export interface LinkCQxDocumentOperationRequest {
    linkCQxDocumentRequest: LinkCQxDocumentRequest;
}

export interface LinkRecordOperationRequest {
    linkRecordRequest: LinkRecordRequest;
}

export interface UnlinkCQxDocumentOperationRequest {
    unlinkCQxDocumentRequest: UnlinkCQxDocumentRequest;
}

export interface UnlinkRecordRequest {
    linkId: string;
}

/**
 * 
 */
export class RecordLinkApi extends runtime.BaseAPI {

    /**
     * Deprecated. Please use GET record/v2/{fromRecordId}/links
     * Retrieves a list of records currently linked to a record using fromRecordId
     */
    async getLinkedRecordsByRecordIdRaw(requestParameters: GetLinkedRecordsByRecordIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Record>>> {
        if (requestParameters.fromRecordId === null || requestParameters.fromRecordId === undefined) {
            throw new runtime.RequiredError('fromRecordId','Required parameter requestParameters.fromRecordId was null or undefined when calling getLinkedRecordsByRecordId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{fromRecordId}/links`.replace(`{${"fromRecordId"}}`, encodeURIComponent(String(requestParameters.fromRecordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RecordFromJSON));
    }

    /**
     * Deprecated. Please use GET record/v2/{fromRecordId}/links
     * Retrieves a list of records currently linked to a record using fromRecordId
     */
    async getLinkedRecordsByRecordId(requestParameters: GetLinkedRecordsByRecordIdRequest, initOverrides?: RequestInit): Promise<Array<Record>> {
        const response = await this.getLinkedRecordsByRecordIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a record link by linkId - license required is Full or Basic
     * Get a record link by linkId
     */
    async getRecordLinkByIdRaw(requestParameters: GetRecordLinkByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordLink>> {
        if (requestParameters.linkId === null || requestParameters.linkId === undefined) {
            throw new runtime.RequiredError('linkId','Required parameter requestParameters.linkId was null or undefined when calling getRecordLinkById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/link/v1/{linkId}`.replace(`{${"linkId"}}`, encodeURIComponent(String(requestParameters.linkId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordLinkFromJSON(jsonValue));
    }

    /**
     * Get a record link by linkId - license required is Full or Basic
     * Get a record link by linkId
     */
    async getRecordLinkById(requestParameters: GetRecordLinkByIdRequest, initOverrides?: RequestInit): Promise<RecordLink> {
        const response = await this.getRecordLinkByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of records currently linked to a record, and a list of record links - license required is Full or Basic
     * Retrieves a list of records currently linked to a record using fromElementId, and a list of record links
     */
    async getRecordLinkResponseByFromElementIdRaw(requestParameters: GetRecordLinkResponseByFromElementIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordLinkResponse>> {
        if (requestParameters.fromElementId === null || requestParameters.fromElementId === undefined) {
            throw new runtime.RequiredError('fromElementId','Required parameter requestParameters.fromElementId was null or undefined when calling getRecordLinkResponseByFromElementId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/element/v2/{fromElementId}/links`.replace(`{${"fromElementId"}}`, encodeURIComponent(String(requestParameters.fromElementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordLinkResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves a list of records currently linked to a record, and a list of record links - license required is Full or Basic
     * Retrieves a list of records currently linked to a record using fromElementId, and a list of record links
     */
    async getRecordLinkResponseByFromElementId(requestParameters: GetRecordLinkResponseByFromElementIdRequest, initOverrides?: RequestInit): Promise<RecordLinkResponse> {
        const response = await this.getRecordLinkResponseByFromElementIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves record links and records that are linked to this record. This implies a bidirectional link if selected - license required is Full or Basic
     * Retrieves record links and records that are linked to this record. This implies a bidirectional link if selected.
     */
    async getRecordLinkResponseByRecordIdRaw(requestParameters: GetRecordLinkResponseByRecordIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordLinkResponse>> {
        if (requestParameters.fromRecordId === null || requestParameters.fromRecordId === undefined) {
            throw new runtime.RequiredError('fromRecordId','Required parameter requestParameters.fromRecordId was null or undefined when calling getRecordLinkResponseByRecordId.');
        }

        const queryParameters: any = {};

        if (requestParameters.bidirectional !== undefined) {
            queryParameters['bidirectional'] = requestParameters.bidirectional;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v2/{fromRecordId}/links`.replace(`{${"fromRecordId"}}`, encodeURIComponent(String(requestParameters.fromRecordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordLinkResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves record links and records that are linked to this record. This implies a bidirectional link if selected - license required is Full or Basic
     * Retrieves record links and records that are linked to this record. This implies a bidirectional link if selected.
     */
    async getRecordLinkResponseByRecordId(requestParameters: GetRecordLinkResponseByRecordIdRequest, initOverrides?: RequestInit): Promise<RecordLinkResponse> {
        const response = await this.getRecordLinkResponseByRecordIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deprecated. Please use GET element/v2/{fromElementId}/links
     * Retrieves a list of records currently linked to a record using fromElementId
     */
    async getRecordsLinkedByFromElementIdRaw(requestParameters: GetRecordsLinkedByFromElementIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Record>>> {
        if (requestParameters.fromElementId === null || requestParameters.fromElementId === undefined) {
            throw new runtime.RequiredError('fromElementId','Required parameter requestParameters.fromElementId was null or undefined when calling getRecordsLinkedByFromElementId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/element/v1/{fromElementId}/links`.replace(`{${"fromElementId"}}`, encodeURIComponent(String(requestParameters.fromElementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RecordFromJSON));
    }

    /**
     * Deprecated. Please use GET element/v2/{fromElementId}/links
     * Retrieves a list of records currently linked to a record using fromElementId
     */
    async getRecordsLinkedByFromElementId(requestParameters: GetRecordsLinkedByFromElementIdRequest, initOverrides?: RequestInit): Promise<Array<Record>> {
        const response = await this.getRecordsLinkedByFromElementIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Link a record to a CQx Document - license required is Full or Basic
     * Link a record to a CQx Document
     */
    async linkCQxDocumentRaw(requestParameters: LinkCQxDocumentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CQxDocumentLink>> {
        if (requestParameters.linkCQxDocumentRequest === null || requestParameters.linkCQxDocumentRequest === undefined) {
            throw new runtime.RequiredError('linkCQxDocumentRequest','Required parameter requestParameters.linkCQxDocumentRequest was null or undefined when calling linkCQxDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/link/v1/cqx`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkCQxDocumentRequestToJSON(requestParameters.linkCQxDocumentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CQxDocumentLinkFromJSON(jsonValue));
    }

    /**
     * Link a record to a CQx Document - license required is Full or Basic
     * Link a record to a CQx Document
     */
    async linkCQxDocument(requestParameters: LinkCQxDocumentOperationRequest, initOverrides?: RequestInit): Promise<CQxDocumentLink> {
        const response = await this.linkCQxDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Link a record to another record - license required is Full or Basic
     * Link a record to another record
     */
    async linkRecordRaw(requestParameters: LinkRecordOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordLink>> {
        if (requestParameters.linkRecordRequest === null || requestParameters.linkRecordRequest === undefined) {
            throw new runtime.RequiredError('linkRecordRequest','Required parameter requestParameters.linkRecordRequest was null or undefined when calling linkRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/link/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkRecordRequestToJSON(requestParameters.linkRecordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordLinkFromJSON(jsonValue));
    }

    /**
     * Link a record to another record - license required is Full or Basic
     * Link a record to another record
     */
    async linkRecord(requestParameters: LinkRecordOperationRequest, initOverrides?: RequestInit): Promise<RecordLink> {
        const response = await this.linkRecordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unlink a cqx document from a record - license required is Full or Basic
     * Unlink a cqx document from a record
     */
    async unlinkCQxDocumentRaw(requestParameters: UnlinkCQxDocumentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.unlinkCQxDocumentRequest === null || requestParameters.unlinkCQxDocumentRequest === undefined) {
            throw new runtime.RequiredError('unlinkCQxDocumentRequest','Required parameter requestParameters.unlinkCQxDocumentRequest was null or undefined when calling unlinkCQxDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/link/v1/cqx`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UnlinkCQxDocumentRequestToJSON(requestParameters.unlinkCQxDocumentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unlink a cqx document from a record - license required is Full or Basic
     * Unlink a cqx document from a record
     */
    async unlinkCQxDocument(requestParameters: UnlinkCQxDocumentOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.unlinkCQxDocumentRaw(requestParameters, initOverrides);
    }

    /**
     * Unlink a record from another record - license required is Full or Basic
     * Unlink a record from another record
     */
    async unlinkRecordRaw(requestParameters: UnlinkRecordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.linkId === null || requestParameters.linkId === undefined) {
            throw new runtime.RequiredError('linkId','Required parameter requestParameters.linkId was null or undefined when calling unlinkRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/link/v1/{linkId}`.replace(`{${"linkId"}}`, encodeURIComponent(String(requestParameters.linkId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unlink a record from another record - license required is Full or Basic
     * Unlink a record from another record
     */
    async unlinkRecord(requestParameters: UnlinkRecordRequest, initOverrides?: RequestInit): Promise<void> {
        await this.unlinkRecordRaw(requestParameters, initOverrides);
    }

}
