/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RecordStatusChangeType {
    RequestToClose = 'REQUEST_TO_CLOSE',
    RequestToCancel = 'REQUEST_TO_CANCEL',
    DirectClose = 'DIRECT_CLOSE',
    DirectCancel = 'DIRECT_CANCEL'
}

export function RecordStatusChangeTypeFromJSON(json: any): RecordStatusChangeType {
    return RecordStatusChangeTypeFromJSONTyped(json, false);
}

export function RecordStatusChangeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordStatusChangeType {
    return json as RecordStatusChangeType;
}

export function RecordStatusChangeTypeToJSON(value?: RecordStatusChangeType | null): any {
    return value as any;
}

