import { API_URI_OVERRIDE_STORAGE_KEY, MOCK_AUTH_STORAGE_KEY } from "./application-configuration-storage-keys";
import { deepFreeze, fetchConfig } from "./application-configuration.helpers";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ApplicationConfiguration = Record<string, any>;
export enum ApplicationConfigEventType {
    Changed = "mc:config:changed",
}

const proxyTarget = { _config: {} as ApplicationConfiguration };

export const applicationConfig = new Proxy(proxyTarget, {
    get: (target, prop) => target._config[prop as keyof ApplicationConfiguration],
    set: () => false,
}) as ApplicationConfiguration;

export async function loadApplicationConfiguration<
    TConfiguration extends ApplicationConfiguration = ApplicationConfiguration,
>() {
    // GET the config file from the deployment and parse it
    const config = await fetchConfig();

    // Allow the API URI to be overridden during testing
    const apiUrlOverride = window?.localStorage?.getItem(API_URI_OVERRIDE_STORAGE_KEY);
    if (apiUrlOverride && config.api.rest.urls.default) {
        config.api.rest.urls.default = apiUrlOverride;
    }
    // Allow the authentication to be overridden during testing
    const shouldAuthBeMocked = /true/i.test(window?.localStorage?.getItem(MOCK_AUTH_STORAGE_KEY) ?? "false");
    if (config?.authentication) {
        config.authentication.shouldBeMocked = shouldAuthBeMocked;
    }

    setApplicationConfiguration(config, true);

    return applicationConfig as TConfiguration;
}

export async function clearApplicationConfiguration() {
    proxyTarget._config = {};
    return applicationConfig;
}

/**
 * Set the application configuration.
 * @note This is used in BFF environments as well, so it cannot contain references to `window`
 */
export async function setApplicationConfiguration(config: ApplicationConfiguration, shouldDeepFreeze = true) {
    // Add a timestamp so we know when the config was loaded
    config._updatedAt = Date.now();

    if (shouldDeepFreeze) {
        deepFreeze(config);
    }

    proxyTarget._config = config;

    // For browser environments, notify the configuration changed
    if (typeof window !== "undefined") {
        window.dispatchEvent(new CustomEvent(ApplicationConfigEventType.Changed));
    }
}
