/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * these are domain events that are returned by our event polling endpoint
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    aggregateId: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    updateType: EventUpdateTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof Event
     */
    eventTimestamp: Date;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Event
     */
    changeFieldToValueMap: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    rootAggregateId: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    parentRootAggregateId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Event
     */
    raisedByRecordOwner?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum EventUpdateTypeEnum {
    TaskCreated = 'TASK_CREATED',
    TaskOpened = 'TASK_OPENED',
    TaskPending = 'TASK_PENDING',
    TaskClosed = 'TASK_CLOSED',
    TaskCompleted = 'TASK_COMPLETED',
    TaskBlocked = 'TASK_BLOCKED',
    TaskReopened = 'TASK_REOPENED',
    TaskViewed = 'TASK_VIEWED',
    TaskUnclaimed = 'TASK_UNCLAIMED',
    NodeStarted = 'NODE_STARTED',
    NodeCompleted = 'NODE_COMPLETED',
    NodeUserAdded = 'NODE_USER_ADDED',
    NodeUserUpdated = 'NODE_USER_UPDATED',
    NodeUserRemoved = 'NODE_USER_REMOVED',
    NodeUserReplaced = 'NODE_USER_REPLACED',
    NodeSettingsUpdated = 'NODE_SETTINGS_UPDATED',
    NodeClosed = 'NODE_CLOSED',
    NodeReopened = 'NODE_REOPENED',
    FormElementDataEntered = 'FORM_ELEMENT_DATA_ENTERED',
    RecordLaunched = 'RECORD_LAUNCHED',
    RecordDrafted = 'RECORD_DRAFTED',
    RecordCompleted = 'RECORD_COMPLETED',
    RecordClosed = 'RECORD_CLOSED',
    RecordAborted = 'RECORD_ABORTED',
    RecordLinked = 'RECORD_LINKED',
    RecordTitleChanged = 'RECORD_TITLE_CHANGED',
    RecordUnlinked = 'RECORD_UNLINKED',
    RecordUserAdded = 'RECORD_USER_ADDED',
    RecordUserRemoved = 'RECORD_USER_REMOVED',
    RecordUserUpdated = 'RECORD_USER_UPDATED',
    RecordUserReplaced = 'RECORD_USER_REPLACED',
    RecordSettingsUpdated = 'RECORD_SETTINGS_UPDATED',
    RecordStatusChanged = 'RECORD_STATUS_CHANGED',
    RecordViewed = 'RECORD_VIEWED',
    EsignatureApproved = 'ESIGNATURE_APPROVED',
    EsignatureInvalidated = 'ESIGNATURE_INVALIDATED',
    CorrectionApproved = 'CORRECTION_APPROVED',
    CorrectionRejected = 'CORRECTION_REJECTED',
    CorrectionCreated = 'CORRECTION_CREATED',
    DirectCorrectionCreated = 'DIRECT_CORRECTION_CREATED',
    CorrectionInProgress = 'CORRECTION_IN_PROGRESS',
    CorrectionCompleted = 'CORRECTION_COMPLETED',
    CorrectionClosed = 'CORRECTION_CLOSED',
    ActionItemDueDateExtensionApproved = 'ACTION_ITEM_DUE_DATE_EXTENSION_APPROVED',
    ActionItemDueDateExtensionCancelled = 'ACTION_ITEM_DUE_DATE_EXTENSION_CANCELLED',
    ActionItemDueDateExtensionCreated = 'ACTION_ITEM_DUE_DATE_EXTENSION_CREATED',
    ActionItemDueDateExtensionDenied = 'ACTION_ITEM_DUE_DATE_EXTENSION_DENIED',
    NodeDueDateExtensionApproved = 'NODE_DUE_DATE_EXTENSION_APPROVED',
    NodeDueDateExtensionCancelled = 'NODE_DUE_DATE_EXTENSION_CANCELLED',
    NodeDueDateExtensionCreated = 'NODE_DUE_DATE_EXTENSION_CREATED',
    NodeDueDateExtensionDenied = 'NODE_DUE_DATE_EXTENSION_DENIED',
    FileAttached = 'FILE_ATTACHED',
    FileUnattached = 'FILE_UNATTACHED',
    RulesApplied = 'RULES_APPLIED',
    ActionItemDeleted = 'ACTION_ITEM_DELETED',
    ActionItemClosed = 'ACTION_ITEM_CLOSED',
    FormElementLocked = 'FORM_ELEMENT_LOCKED',
    FormElementUnlocked = 'FORM_ELEMENT_UNLOCKED',
    NodeApproved = 'NODE_APPROVED',
    ApproverAdded = 'APPROVER_ADDED',
    ApproverRemoved = 'APPROVER_REMOVED',
    ApproverReassigned = 'APPROVER_REASSIGNED',
    ApproverRequestedCorrection = 'APPROVER_REQUESTED_CORRECTION',
    ApprovalAdded = 'APPROVAL_ADDED',
    ApprovalInvalidated = 'APPROVAL_INVALIDATED',
    ApproverChanged = 'APPROVER_CHANGED',
    NodeParticipantAdded = 'NODE_PARTICIPANT_ADDED',
    NodeParticipantRemoved = 'NODE_PARTICIPANT_REMOVED',
    NodeParticipantActorChanged = 'NODE_PARTICIPANT_ACTOR_CHANGED',
    NodeParticipantTypeChanged = 'NODE_PARTICIPANT_TYPE_CHANGED',
    RecordParticipantAdded = 'RECORD_PARTICIPANT_ADDED',
    RecordParticipantRemoved = 'RECORD_PARTICIPANT_REMOVED',
    RecordParticipantActorChanged = 'RECORD_PARTICIPANT_ACTOR_CHANGED',
    TaskClaimParticipantCleanup = 'TASK_CLAIM_PARTICIPANT_CLEANUP',
    DocumentLinked = 'DOCUMENT_LINKED',
    DocumentUnlinked = 'DOCUMENT_UNLINKED',
    TableElementRowAdded = 'TABLE_ELEMENT_ROW_ADDED',
    TableElementRowDeleted = 'TABLE_ELEMENT_ROW_DELETED',
    TableElementRowUpdated = 'TABLE_ELEMENT_ROW_UPDATED'
}

export function EventFromJSON(json: any): Event {
    return EventFromJSONTyped(json, false);
}

export function EventFromJSONTyped(json: any, ignoreDiscriminator: boolean): Event {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aggregateId': json['aggregateId'],
        'updateType': json['updateType'],
        'eventTimestamp': new Date(json['eventTimestamp']),
        'changeFieldToValueMap': json['changeFieldToValueMap'],
        'rootAggregateId': json['rootAggregateId'],
        'parentRootAggregateId': !exists(json, 'parentRootAggregateId') ? undefined : json['parentRootAggregateId'],
        'raisedByRecordOwner': !exists(json, 'raisedByRecordOwner') ? undefined : json['raisedByRecordOwner'],
    };
}

export function EventToJSON(value?: Event | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aggregateId': value.aggregateId,
        'updateType': value.updateType,
        'eventTimestamp': value.eventTimestamp.toISOString(),
        'changeFieldToValueMap': value.changeFieldToValueMap,
        'rootAggregateId': value.rootAggregateId,
        'parentRootAggregateId': value.parentRootAggregateId,
        'raisedByRecordOwner': value.raisedByRecordOwner,
    };
}

