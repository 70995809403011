/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ValidationErrorKey {
    WorkflowValidationRecordOwnerUserIdsOrRolesRequired = 'workflow-validation-record-owner-user-ids-or-roles-required',
    WorkflowValidationNumberingPrefixLimit = 'workflow-validation-numbering-prefix-limit',
    WorkflowValidationNumberingPrefixAlphanumeric = 'workflow-validation-numbering-prefix-alphanumeric',
    FormValidationValidate = 'form-validation-validate',
    WorkflowValidationStatusMustBeDraft = 'workflow-validation-status-must-be-draft',
    WorkflowValidationTypeNumberingPrefixChanged = 'workflow-validation-type-numbering-prefix-changed',
    WorkflowValidationNumberingPrefixUnavailable = 'workflow-validation-numbering-prefix-unavailable',
    WorkflowValidationTypeRequired = 'workflow-validation-type-required',
    WorkflowValidationNumberingPrefixRequired = 'workflow-validation-numbering-prefix-required',
    WorkflowValidationDuplicateName = 'workflow-validation-duplicate-name',
    WorkflowValidationMustHaveOnlyOneParallelSplit = 'workflow-validation-must-have-only-one-parallel-split',
    WorkflowValidationMustHaveOnlyOneJoin = 'workflow-validation-must-have-only-one-join',
    WorkflowValidationOnlyOneNodeInPathAllowed = 'workflow-validation-only-one-node-in-path-allowed',
    WorkflowValidationCannotHaveRules = 'workflow-validation-cannot-have-rules',
    WorkflowValidationNameRequired = 'workflow-validation-name-required',
    WorkflowValidationInvalidType = 'workflow-validation-invalid-type',
    WorkflowValidationInvalidNumberingPrefix = 'workflow-validation-invalid-numbering-prefix',
    WorkflowValidationWorkflowHasMoreThanOneNode = 'workflow-validation-workflow-has-more-than-one-node',
    WorkflowValidationNodeCanNotHaveUserOrGroupId = 'workflow-validation-node-can-not-have-user-or-group-id',
    WorkflowValidationWorkflowNodeIsNotTypeStep = 'workflow-validation-workflow-node-is-not-type-step',
    WorkflowValidationNumberingPrefixMustBeEmpty = 'workflow-validation-numbering-prefix-must-be-empty',
    WorkflowValidationLogbooksEntryAllExclusionsMustBeTrue = 'workflow-validation-logbooks-entry-all-exclusions-must-be-true',
    WorkflowValidationRecordOwnerUsersNeedFullLicense = 'workflow-validation-record-owner-users-need-full-license',
    WorkflowValidationNoSitesFoundInSystem = 'workflow-validation-no-sites-found-in-system',
    NodeValidationCannotSplitWithNodeType = 'node-validation-cannot-split-with-node-type',
    NodeValidationCannotJoinWithNodeType = 'node-validation-cannot-join-with-node-type',
    NodeValidationSplitsAndJoinsMustMatch = 'node-validation-splits-and-joins-must-match',
    NodeValidationFirstNodeMustBeOfTypeStartOrStep = 'node-validation-first-node-must-be-of-type-start-or-step',
    NodeValidationOnlyOneRootNodeAllowed = 'node-validation-only-one-root-node-allowed',
    NodeValidationOnlyOneLeafNodeAllowed = 'node-validation-only-one-leaf-node-allowed',
    NodeValidationOnlyOneStartNodeAllowed = 'node-validation-only-one-start-node-allowed',
    NodeValidationEndNodeRequired = 'node-validation-end-node-required',
    NodeValidationOnlyOneEndNodeAllowed = 'node-validation-only-one-end-node-allowed',
    NodeValidationStartNotRootNode = 'node-validation-start-not-root-node',
    NodeValidationEndNotLeafNode = 'node-validation-end-not-leaf-node',
    NodeValidationOrphanedNodesNotAllowed = 'node-validation-orphaned-nodes-not-allowed',
    NodeValidationNameRequired = 'node-validation-name-required',
    NodeValidationUserIdsOrRolesRequired = 'node-validation-user-ids-or-roles-required',
    NodeValidationEmptyRole = 'node-validation-empty-role',
    NodeValidationNodeRequired = 'node-validation-node-required',
    NodeValidationApprovalNodeCannotBeFirst = 'node-validation-approval-node-cannot-be-first',
    NodeValidationApprovalNodeCannotHaveSections = 'node-validation-approval-node-cannot-have-sections',
    NodeValidationApprovalNodeCannotHaveFormElement = 'node-validation-approval-node-cannot-have-form-element',
    NodeValidationApprovalNodeEsigRequired = 'node-validation-approval-node-esig-required',
    NodeValidationParallelSplitCannotHaveRules = 'node-validation-parallel-split-cannot-have-rules',
    NodeValidationApprovalNodeRequestChangesNotAllowed = 'node-validation-approval-node-request-changes-not-allowed',
    NodeValidationNodeTypeNotAllowed = 'node-validation-node-type-not-allowed',
    NodeValidationOnlyOneSectionAllowed = 'node-validation-only-one-section-allowed',
    NodeValidationMustHaveEndAfterJoin = 'node-validation-must-have-end-after-join',
    NodeValidationEndNodeCannotHaveSections = 'node-validation-end-node-cannot-have-sections',
    NodeValidationFirstNodeMustBeSplit = 'node-validation-first-node-must-be-split',
    NodeValidationEndNodeCannotHaveAssignees = 'node-validation-end-node-cannot-have-assignees',
    NodeValidationMustBeTrueRequestChanges = 'node-validation-must-be-true-request-changes',
    NodeValidationMustBeFalseRequestChanges = 'node-validation-must-be-false-request-changes',
    NodeValidationAssigneeUsersNeedFullLicense = 'node-validation-assignee-users-need-full-license',
    SectionValidationMissingNodeId = 'section-validation-missing-node-id',
    SectionValidationOnlyOneFormElementAllowed = 'section-validation-only-one-form-element-allowed',
    SectionValidationMustBeTrueEnabled = 'section-validation-must-be-true-enabled',
    SectionValidationMustBeTrueVisible = 'section-validation-must-be-true-visible',
    FormElementValidationNoValidSectionId = 'form-element-validation-no-valid-section-id',
    FormElementValidationNameRequired = 'form-element-validation-name-required',
    FormElementValidationOptionsRequired = 'form-element-validation-options-required',
    FormElementValidationOptionsValueRequired = 'form-element-validation-options-value-required',
    FormElementValidationOptionsNoDuplicateValue = 'form-element-validation-options-no-duplicate-value',
    FormElementValidationMustBeTrueEnabled = 'form-element-validation-must-be-true-enabled',
    FormElementValidationMustBeTrueVisible = 'form-element-validation-must-be-true-visible',
    FormElementValidationMustBeTrueShowHintText = 'form-element-validation-must-be-true-show-hint-text',
    FormElementValidationMustBeTrueAllowNegative = 'form-element-validation-must-be-true-allow-negative',
    FormElementValidationMustBeTrueRequired = 'form-element-validation-must-be-true-required',
    FormElementValidationToggleCaptureModeRequired = 'form-element-validation-toggle-capture-mode-required',
    FormElementValidationOptionsAndOptionsListIdAreMutuallyExclusive = 'form-element-validation-options-and-options-list-id-are-mutually-exclusive',
    FormElementValidationInvalidOptionsListId = 'form-element-validation-invalid-options-list-id',
    FormElementValidationListMustBeEnabled = 'form-element-validation-list-must-be-enabled',
    FormElementValidationListNotFound = 'form-element-validation-list-not-found',
    FormElementOptionExceedsSizeLimit = 'form-element-option-exceeds-size-limit',
    FormElementValidationParentIdMustBeNullOrReferenceTable = 'form-element-validation-parent-id-must-be-null-or-reference-table',
    FormElementValidationMustHaveSectionOrParentId = 'form-element-validation-must-have-section-or-parent-id',
    FormElementValidationTableCannotReferenceATable = 'form-element-validation-table-cannot-reference-a-table',
    FormElementValidationTableMaxRowLimit = 'form-element-validation-table-max-row-limit',
    FormElementValidationTableMinRowLimit = 'form-element-validation-table-min-row-limit',
    FormElementValidationTableMaxRowMustBeLargerOrEqualMinRow = 'form-element-validation-table-max-row-must-be-larger-or-equal-min-row',
    FormElementValidationTableRowLabelLimit = 'form-element-validation-table-row-label-limit',
    FormElementValidationTableChildMustNotBeTargetOfRule = 'form-element-validation-table-child-must-not-be-target-of-rule',
    FormElementValidationTableDisplayOrderMustBeContiguous = 'form-element-validation-table-display-order-must-be-contiguous',
    FormElementValidationTableChildElementsRequired = 'form-element-validation-table-child-elements-required',
    RuleValidationSourceSectionCannotBeSameAsTargetSection = 'rule-validation-source-section-cannot-be-same-as-target-section',
    RuleValidationTargetFormElementNotFound = 'rule-validation-target-form-element-not-found',
    RuleValidationTargetSectionNotFound = 'rule-validation-target-section-not-found',
    RuleValidationTargetSectionNodeNotFound = 'rule-validation-target-section-node-not-found',
    RuleValidationSectionOnInvalidNode = 'rule-validation-section-on-invalid-node',
    RuleValidationInvalidRuleAction = 'rule-validation-invalid-rule-action',
    RuleValidationCircularRule = 'rule-validation-circular-rule',
    RuleValidationRuleNodeNotFound = 'rule-validation-rule-node-not-found',
    RuleValidationSourceNodeTypeIsNotStepOrStart = 'rule-validation-source-node-type-is-not-step-or-start',
    RuleValidationNodeLinkedToAnotherRule = 'rule-validation-node-linked-to-another-rule',
    RuleValidationTargetNodeNotDownstreamFromSource = 'rule-validation-target-node-not-downstream-from-source',
    RuleValidationTargetSectionNotDownstreamFromSource = 'rule-validation-target-section-not-downstream-from-source',
    RuleValidationTargetFormElementNotDownstreamFromSource = 'rule-validation-target-form-element-not-downstream-from-source',
    RuleValidationRulesNotAllowedForApplication = 'rule-validation-rules-not-allowed-for-application',
    RuleValidationFormElementLinkedToAnotherRule = 'rule-validation-form-element-linked-to-another-rule',
    PermissionRuleValidationWorkflowSitesRequired = 'permission-rule-validation-workflow-sites-required',
    PermissionRuleValidationConditionRequired = 'permission-rule-validation-condition-required',
    PermissionRuleValidationConditionIncomplete = 'permission-rule-validation-condition-incomplete',
    PermissionRuleValidationEventOwnerRuleRequired = 'permission-rule-validation-event-owner-rule-required',
    PermissionRuleValidationEventInitiatorRuleRequired = 'permission-rule-validation-event-initiator-rule-required',
    PermissionRuleValidationStepParticipantRuleRequired = 'permission-rule-validation-step-participant-rule-required',
    PermissionRuleValidationWorkflowSitesValueInvalid = 'permission-rule-validation-workflow-sites-value-invalid',
    PermissionRuleValidationRuleSitesValueInvalid = 'permission-rule-validation-rule-sites-value-invalid',
    PermissionRuleValidationRuleDepartmentValueInvalid = 'permission-rule-validation-rule-department-value-invalid',
    PermissionRuleValidationRuleRoleValueInvalid = 'permission-rule-validation-rule-role-value-invalid',
    PermissionRuleValidationAssignedUserNotMatchedWithRuleConditions = 'permission-rule-validation-assigned-user-not-matched-with-rule-conditions',
    PermissionRuleValidationNoUserFoundForRuleDefinition = 'permission-rule-validation-no-user-found-for-rule-definition',
    PermissionRuleValidationNoUserFoundForEachPossibleEventAffectedSite = 'permission-rule-validation-no-user-found-for-each-possible-event-affected-site',
    ApprovalValidationApproversRequired = 'approval-validation-approvers-required',
    ApprovalValidationApproversEmptyRoles = 'approval-validation-approvers-empty-roles',
    ApprovalValidationWorkflowApprovalOneRequired = 'approval-validation-workflow-approval-one-required',
    ApprovalValidationWorkflowApprovalOnlyAllowedOnEndNode = 'approval-validation-workflow-approval-only-allowed-on-end-node',
    ApprovalValidationWorkflowApprovalRequireRationaleRequired = 'approval-validation-workflow-approval-require-rationale-required',
    ApprovalValidationUsersNeedFullLicense = 'approval-validation-users-need-full-license',
    TemplateAlreadyExists = 'template-already-exists',
    TemplateWorkflowCannotBeDraft = 'template-workflow-cannot-be-draft',
    TemplateWorkflowContainsCustomLists = 'template-workflow-contains-custom-lists',
    TemplateWorkflowContainsCustomTags = 'template-workflow-contains-custom-tags',
    VodValidationRequiredMustBeSet = 'vod-validation-required-must-be-set',
    VodValidationJustificationRequired = 'vod-validation-justification-required',
    VodValidationRequiredButNotValidated = 'vod-validation-required-but-not-validated',
    VodValidationReportRequired = 'vod-validation-report-required',
    VodPassedValidationPlanRequired = 'vod-passed-validation-plan-required'
}

export function ValidationErrorKeyFromJSON(json: any): ValidationErrorKey {
    return ValidationErrorKeyFromJSONTyped(json, false);
}

export function ValidationErrorKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationErrorKey {
    return json as ValidationErrorKey;
}

export function ValidationErrorKeyToJSON(value?: ValidationErrorKey | null): any {
    return value as any;
}

