/* tslint:disable */
/* eslint-disable */
/**
 * File Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeleteFileResponse,
    DeleteFileResponseFromJSON,
    DeleteFileResponseToJSON,
    FileScanStatusResponse,
    FileScanStatusResponseFromJSON,
    FileScanStatusResponseToJSON,
    PresignedUrlResponse,
    PresignedUrlResponseFromJSON,
    PresignedUrlResponseToJSON,
    S3FileInfo,
    S3FileInfoFromJSON,
    S3FileInfoToJSON,
} from '../models';

export interface DeleteFileRequest {
    fileName: string;
    folderId: string;
}

export interface GetFileScanStatusRequest {
    fileName: string;
    folderId: string;
}

export interface GetFilesByFolderIdRequest {
    folderId: string;
}

export interface PresignedFileUrlRequest {
    fileName: string;
    httpMethod: string;
    folderId: string;
}

/**
 * 
 */
export class FilesApi extends runtime.BaseAPI {

    /**
     * Deletes a file. License required is \'Full\', or \'Basic\'.
     */
    async deleteFileRaw(requestParameters: DeleteFileRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeleteFileResponse>> {
        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
            throw new runtime.RequiredError('fileName','Required parameter requestParameters.fileName was null or undefined when calling deleteFile.');
        }

        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling deleteFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.fileName !== undefined) {
            queryParameters['fileName'] = requestParameters.fileName;
        }

        if (requestParameters.folderId !== undefined) {
            queryParameters['folderId'] = requestParameters.folderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/file-service/file/v1`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteFileResponseFromJSON(jsonValue));
    }

    /**
     * Deletes a file. License required is \'Full\', or \'Basic\'.
     */
    async deleteFile(requestParameters: DeleteFileRequest, initOverrides?: RequestInit): Promise<DeleteFileResponse> {
        const response = await this.deleteFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the file scan status. License required is \'Full\', or \'Basic\'.
     */
    async getFileScanStatusRaw(requestParameters: GetFileScanStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileScanStatusResponse>> {
        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
            throw new runtime.RequiredError('fileName','Required parameter requestParameters.fileName was null or undefined when calling getFileScanStatus.');
        }

        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling getFileScanStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.fileName !== undefined) {
            queryParameters['fileName'] = requestParameters.fileName;
        }

        if (requestParameters.folderId !== undefined) {
            queryParameters['folderId'] = requestParameters.folderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/file-service/file/v1/scan/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileScanStatusResponseFromJSON(jsonValue));
    }

    /**
     * Gets the file scan status. License required is \'Full\', or \'Basic\'.
     */
    async getFileScanStatus(requestParameters: GetFileScanStatusRequest, initOverrides?: RequestInit): Promise<FileScanStatusResponse> {
        const response = await this.getFileScanStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets files in a folder using the folder id. License required is \'Full\', or \'Basic\'.
     */
    async getFilesByFolderIdRaw(requestParameters: GetFilesByFolderIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<S3FileInfo>>> {
        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling getFilesByFolderId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/file-service/files/v1/folder/{folderId}`.replace(`{${"folderId"}}`, encodeURIComponent(String(requestParameters.folderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(S3FileInfoFromJSON));
    }

    /**
     * Gets files in a folder using the folder id. License required is \'Full\', or \'Basic\'.
     */
    async getFilesByFolderId(requestParameters: GetFilesByFolderIdRequest, initOverrides?: RequestInit): Promise<Array<S3FileInfo>> {
        const response = await this.getFilesByFolderIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates a presigned url with file name. License required is \'Full\', or \'Basic\'.
     */
    async presignedFileUrlRaw(requestParameters: PresignedFileUrlRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PresignedUrlResponse>> {
        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
            throw new runtime.RequiredError('fileName','Required parameter requestParameters.fileName was null or undefined when calling presignedFileUrl.');
        }

        if (requestParameters.httpMethod === null || requestParameters.httpMethod === undefined) {
            throw new runtime.RequiredError('httpMethod','Required parameter requestParameters.httpMethod was null or undefined when calling presignedFileUrl.');
        }

        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling presignedFileUrl.');
        }

        const queryParameters: any = {};

        if (requestParameters.fileName !== undefined) {
            queryParameters['fileName'] = requestParameters.fileName;
        }

        if (requestParameters.httpMethod !== undefined) {
            queryParameters['httpMethod'] = requestParameters.httpMethod;
        }

        if (requestParameters.folderId !== undefined) {
            queryParameters['folderId'] = requestParameters.folderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/file-service/presigned-url/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PresignedUrlResponseFromJSON(jsonValue));
    }

    /**
     * Generates a presigned url with file name. License required is \'Full\', or \'Basic\'.
     */
    async presignedFileUrl(requestParameters: PresignedFileUrlRequest, initOverrides?: RequestInit): Promise<PresignedUrlResponse> {
        const response = await this.presignedFileUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
