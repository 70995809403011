import { colors } from "./colors";

interface ButtonColors {
    default: string;
    disabled: string;
    hover: string;
}

export interface Purposes {
    analyticsLabels: {
        background: {
            default: string;
            primary: string;
            secondary: string;
        };
        chip: {
            primary: {
                closeIcon: {
                    chipHover: string;
                    default: string;
                    hover: string;
                };
                default: string;
                icon: {
                    default: string;
                    text: string;
                };
            };
            secondary: {
                closeIcon: {
                    chipHover: string;
                    default: string;
                    hover: string;
                };
                default: string;
                icon: {
                    default: string;
                    text: string;
                };
            };
        };
        listItem: {
            primary: {
                background: string;
                border: string;
                text: string;
            };
            secondary: {
                background: string;
                border: string;
                text: string;
            };
        };
    };
    avatars: {
        background: {
            default: readonly string[];
            more: {
                active: string;
                default: string;
            };
        };
        color: {
            default: string;
            more: string;
        };
    };
    backgrounds: {
        default: string;
        disabled: string;
        input: {
            disabled: string;
        };
        primary: string;
        secondary: string;
        tertiary: string;
        transparent: string;
    };
    badges: {
        active: string;
        listTypeBadge: {
            custom: {
                background: string;
                border: string;
                text: string;
            };
            mc: {
                background: string;
                border: string;
                text: string;
            };
        };
    };
    borders: {
        default: string;
        input: {
            active: string;
            default: string;
            disabled: string;
            error: string;
            success: string;
        };
        outline: string;
    };
    buttons: {
        destructive: ButtonColors;
        info: ButtonColors;
        primary: ButtonColors;
        secondary: ButtonColors;
        success: ButtonColors;
        warning: ButtonColors;
    };
    formElement: {
        background: {
            locked: string;
        };
    };
    gradients: {
        leftPrimaryToRightSecondary: string;
        leftSecondaryToRightPrimary: string;
        topLeftSecondaryToBottomPrimary: string;
        topPrimaryToBottomSecondary: string;
        topSecondaryToBottomPrimary: string;
    };

    navBar: {
        backgroundColor: string;
        color: string;
        dividerColor: string;
        headerBackgroundColor: string;
    };
    table: {
        background: {
            alternate: string;
            default: string;
            highlighted: string;
            selected: string;
        };
    };

    text: {
        default: string;
        disabled: string;
        input: {
            active: string;
            error: string;
            placeholder: string;
            readonly: string;
            title: string;
        };
        secondary: string;
        success: string;
    };

    workflow: {
        edge: {
            default: string;
            disabled: string;
            viewOnly: string;
        };
        newWorkflowButton: {
            backgroundDefault: string;
            backgroundHover: string;
            colorDefault: string;
            colorHover: string;
        };
        template: {
            card: {
                iconBackground: string;
            };
            details: {
                analytics: {
                    background: string;
                    icon: string;
                };
                bestPractices: {
                    background: string;
                    icon: string;
                };
            };
        };
        validationErrors: {
            background: {
                default: string;
            };
        };
    };
}

export const purposes: { light: Purposes } = {
    light: {
        analyticsLabels: {
            background: {
                default: "#F4F7FA",
                primary: colors.light.blue[600],
                secondary: colors.light.magenta[600],
            },
            chip: {
                primary: {
                    closeIcon: {
                        chipHover: colors.light.blue[400],
                        default: colors.light.blue[300],
                        hover: colors.light.blue[500],
                    },
                    default: colors.light.blue[100],
                    icon: {
                        default: colors.light.blue[200],
                        text: colors.light.blue[600],
                    },
                },
                secondary: {
                    closeIcon: {
                        chipHover: colors.light.magenta[400],
                        default: colors.light.magenta[300],
                        hover: colors.light.magenta[500],
                    },
                    default: colors.light.magenta[50] ?? colors.light.magenta[100],
                    icon: {
                        default: colors.light.magenta[100],
                        text: colors.light.magenta[600],
                    },
                },
            },
            listItem: {
                primary: {
                    background: colors.light.blue[100],
                    border: colors.light.blue[300],
                    text: colors.light.blue[600],
                },
                secondary: {
                    background: colors.light.magenta[50] ?? colors.light.magenta[100],
                    border: colors.light.magenta[300],
                    text: colors.light.magenta[600],
                },
            },
        },
        avatars: {
            // Colors represented as array indexes: https://www.figma.com/file/JN6S1NpaHRUzfrgLVVH3aP/MC-Design-System?type=design&node-id=1487-431&mode=design&t=RsNa2ceokq9F7aqE-4
            background: {
                default: [
                    "#337F8D",
                    "#66A34D",
                    "#C53495",
                    "#1E88E5",
                    "#CA4D23",
                    "#0097A7",
                    "#A4AF36",
                    "#E32F92",
                    "#0288D1",
                    "#FF5722",
                    "#2A6974",
                    "#558E44",
                    "#A32A7B",
                    "#1565C0",
                    "#F2754C",
                ] as const,
                more: {
                    active: "#C3C5C8",
                    default: "#F4F7FA",
                },
            },
            color: {
                default: colors.white,
                more: "#313233",
            },
        },
        backgrounds: {
            default: "#FAFAFA",
            disabled: "#EDEEF0",
            input: {
                disabled: "#f9fafa",
            },
            primary: "#f4f7fa",
            secondary: colors.white,
            tertiary: "#eef2f5",
            transparent: "transparent",
        },
        badges: {
            active: colors.light.green.main,
            listTypeBadge: {
                custom: {
                    background: colors.light.magenta[50] ?? colors.light.magenta[100],
                    border: colors.light.magenta[200],
                    text: colors.light.magenta[700],
                },
                mc: {
                    background: colors.light.blue[100],
                    border: colors.light.blue[300],
                    text: colors.light.blue[700],
                },
            },
        },
        borders: {
            default: colors.light.grey[400],
            input: {
                active: colors.light.teal[600],
                default: "#bec4c5",
                disabled: colors.light.grey[400],
                error: colors.light.red.main,
                success: colors.light.green.main,
            },
            outline: "rgba(0, 0, 0, 0.23)",
        },
        buttons: {
            destructive: {
                default: colors.light.red.main,
                disabled: colors.light.red.light,
                hover: colors.light.red.dark,
            },
            info: {
                default: colors.light.blue.main,
                disabled: colors.light.blue.light,
                hover: colors.light.blue.dark,
            },
            primary: {
                default: colors.light.teal.main,
                disabled: colors.light.teal.light,
                hover: colors.light.teal.dark,
            },
            secondary: {
                default: colors.light.magenta.main,
                disabled: colors.light.magenta.light,
                hover: colors.light.magenta.dark,
            },
            success: {
                default: colors.light.green.main,
                disabled: colors.light.green.light,
                hover: colors.light.green.dark,
            },
            warning: {
                default: colors.light.yellow.main,
                disabled: colors.light.yellow.light,
                hover: colors.light.yellow.dark,
            },
        },
        formElement: {
            background: {
                locked: "#F6F7F9",
            },
        },
        gradients: {
            leftPrimaryToRightSecondary: `linear-gradient(90deg, ${colors.light.teal.main} 0%, ${colors.light.magenta.main} 100%)`,
            leftSecondaryToRightPrimary: `linear-gradient(90deg, ${colors.light.magenta.main} 0%, ${colors.light.teal.main} 100%)`,
            topLeftSecondaryToBottomPrimary: `linear-gradient(to bottom right, ${colors.light.magenta.main} 0%, ${colors.light.teal.main} 100%)`,
            topPrimaryToBottomSecondary: `linear-gradient(360deg, ${colors.light.magenta.main} 0%, ${colors.light.teal.main} 100%)`,
            topSecondaryToBottomPrimary: `linear-gradient(180deg, ${colors.light.magenta.main} 0%, ${colors.light.teal.main} 100%)`,
        },

        navBar: {
            backgroundColor: "darkPrimary.dark",
            color: "#eeeeee",
            dividerColor: colors.light.grey[300],
            headerBackgroundColor: "darkPrimary.main",
        },
        table: {
            background: {
                alternate: colors.light.grey[50] ?? colors.light.grey[100],
                default: colors.white,
                highlighted: colors.light.grey[100],
                selected: colors.light.teal[50] ?? colors.light.teal[100],
            },
        },
        text: {
            default: "#303030",
            disabled: "#70728f",
            input: {
                active: "#16363c",
                error: "#970c0c",
                placeholder: "#999999",
                readonly: "#303030",
                title: "#707070",
            },
            secondary: "#636465",
            success: "#27682a",
        },
        workflow: {
            edge: {
                default: "#8A8B8C",
                disabled: "#C3C5C8",
                viewOnly: "#A5A7AA",
            },
            newWorkflowButton: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                backgroundDefault: colors.light.teal[100],
                backgroundHover: colors.light.teal[500],
                colorDefault: colors.light.teal[500],
                colorHover: colors.white,
            },
            template: {
                card: {
                    iconBackground: colors.light.grey[200],
                },
                details: {
                    analytics: {
                        background: colors.light.magenta[100],
                        icon: colors.light.magenta[500],
                    },
                    bestPractices: {
                        background: colors.light.green[100],
                        icon: colors.light.green[500],
                    },
                },
            },
            validationErrors: {
                background: {
                    default: "#F4F7FA",
                },
            },
        },
    },
};
