/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordStatus
 */
export interface RecordStatus {
    /**
     * 
     * @type {string}
     * @memberof RecordStatus
     */
    value?: RecordStatusValueEnum;
}

/**
* @export
* @enum {string}
*/
export enum RecordStatusValueEnum {
    Open = 'OPEN',
    Closed = 'CLOSED',
    Draft = 'DRAFT',
    Aborted = 'ABORTED',
    Completed = 'COMPLETED',
    ClosedPrematurely = 'CLOSED_PREMATURELY',
    Cancelled = 'CANCELLED'
}

export function RecordStatusFromJSON(json: any): RecordStatus {
    return RecordStatusFromJSONTyped(json, false);
}

export function RecordStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function RecordStatusToJSON(value?: RecordStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
    };
}

