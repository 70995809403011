/**
 * The shape of a color, as defined in the design system.
 */
interface ColorPalette {
    100: string;
    200: string;
    300: string;
    400: string;
    50?: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    contrastText?: string;
    dark: string;
    light: string;
    main: string;
}

// Colors
export const colors = {
    black: "#000000",
    light: {
        blue: {
            100: "#c2e3ff",
            200: "#99d1ff",
            300: "#70bfff",
            400: "#33a3ff",
            50: "#dce7f3",
            500: "#077ad7",
            600: "#0065b8",
            700: "#00437a",
            800: "#002d52",
            900: "#001629",
            dark: "#00437a",
            light: "#dce7f3",
            main: "#077ad7",
        } as ColorPalette,
        green: {
            100: "#c3e9c5",
            200: "#97d89a",
            300: "#6ac86f",
            400: "#42b348",
            50: "#f0f9eb",
            500: "#2e7d32",
            600: "#27682a",
            700: "#1c4a1e",
            800: "#112d12",
            900: "#0b1e0c",
            dark: "#1c4a1e",
            light: "#c3e9c5",
            main: "#2e7d32",
        } as ColorPalette,
        grey: {
            100: "#f7f7f9",
            200: "#f2f2f5",
            25: "#F9F9F9",
            300: "#ececee",
            400: "#cacacd",
            50: "#fcfcfe",
            500: "#adadaf",
            600: "#828285",
            700: "#6e6e70",
            800: "#4e4e50",
            900: "#2c2c2e",
            dark: "#2c2c2e",
            light: "#f7f7f9",
            main: "#adadaf",
        } as ColorPalette,
        magenta: {
            100: "#eebfde",
            200: "#e59ece",
            300: "#dd7ebd",
            400: "#d45ead",
            50: "#f6deee",
            500: "#c53495",
            600: "#b12f86",
            700: "#812261",
            800: "#51153d",
            900: "#200918",
            dark: "#812261",
            light: "#eebfde",
            main: "#c53495",
        } as ColorPalette,
        red: {
            100: "#fddbd8",
            200: "#f9928b",
            300: "#f9928b",
            400: "#f76e64",
            500: "#c71010",
            600: "#970c0c",
            700: "#710909",
            800: "#4b0606",
            900: "#260303",
            dark: "#710909",
            light: "#fddbd8",
            main: "#c71010",
        } as ColorPalette,
        teal: {
            100: "#d2eaef",
            200: "#a5d6df",
            25: "#F4F7FA",
            300: "#78c1ce",
            400: "#4badbe",
            50: "#EFF6F8",
            500: "#337f8d",
            600: "#2b6c78",
            700: "#20515a",
            800: "#16363c",
            900: "#0b1b1e",
            contrastText: "#d8f2ff",
            dark: "#20515a",
            light: "#d2eaef",
            main: "#337f8d",
            /** @deprecated */
            shade700percent4: "#3b93a30a",
        } as ColorPalette,
        yellow: {
            100: "#fff3d6",
            200: "#ffce5c",
            300: "#ffce5c",
            400: "#ffc233",
            500: "#ffb400",
            600: "#e09d00",
            700: "#a37200",
            800: "#664700",
            900: "#291d00",
            dark: "#a37200",
            light: "#fff3d6",
            main: "#ffb400",
        } as ColorPalette,
    },
    white: "#ffffff",
};
