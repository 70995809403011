/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeploySolutionRequest
 */
export interface DeploySolutionRequest {
    /**
     * 
     * @type {string}
     * @memberof DeploySolutionRequest
     */
    customerTenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploySolutionRequest
     */
    solutionId?: string;
}

export function DeploySolutionRequestFromJSON(json: any): DeploySolutionRequest {
    return DeploySolutionRequestFromJSONTyped(json, false);
}

export function DeploySolutionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploySolutionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerTenantId': !exists(json, 'customerTenantId') ? undefined : json['customerTenantId'],
        'solutionId': !exists(json, 'solutionId') ? undefined : json['solutionId'],
    };
}

export function DeploySolutionRequestToJSON(value?: DeploySolutionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerTenantId': value.customerTenantId,
        'solutionId': value.solutionId,
    };
}

