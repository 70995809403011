/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ValidationErrorCode {
    _00001 = '07-00001',
    _01000 = '07-01000',
    _01001 = '07-01001',
    _01002 = '07-01002',
    _01003 = '07-01003',
    _01004 = '07-01004',
    _01005 = '07-01005',
    _01006 = '07-01006',
    _01007 = '07-01007',
    _01008 = '07-01008',
    _01009 = '07-01009',
    _01010 = '07-01010',
    _01011 = '07-01011',
    _01012 = '07-01012',
    _01013 = '07-01013',
    _01014 = '07-01014',
    _01015 = '07-01015',
    _01016 = '07-01016',
    _01017 = '07-01017',
    _01018 = '07-01018',
    _01019 = '07-01019',
    _01022 = '07-01022',
    _01023 = '07-01023',
    _01024 = '07-01024',
    _11000 = '07-11000',
    _11001 = '07-11001',
    _11002 = '07-11002',
    _11003 = '07-11003',
    _11004 = '07-11004',
    _11005 = '07-11005',
    _11006 = '07-11006',
    _11007 = '07-11007',
    _11008 = '07-11008',
    _11009 = '07-11009',
    _11010 = '07-11010',
    _11011 = '07-11011',
    _11012 = '07-11012',
    _11013 = '07-11013',
    _11014 = '07-11014',
    _11015 = '07-11015',
    _11016 = '07-11016',
    _11017 = '07-11017',
    _11018 = '07-11018',
    _11019 = '07-11019',
    _11020 = '07-11020',
    _11021 = '07-11021',
    _11022 = '07-11022',
    _11023 = '07-11023',
    _11024 = '07-11024',
    _11025 = '07-11025',
    _11026 = '07-11026',
    _11027 = '07-11027',
    _11028 = '07-11028',
    _11029 = '07-11029',
    _11030 = '07-11030',
    _31000 = '07-31000',
    _31001 = '07-31001',
    _31002 = '07-31002',
    _31003 = '07-31003',
    _41000 = '07-41000',
    _41001 = '07-41001',
    _41002 = '07-41002',
    _41003 = '07-41003',
    _41004 = '07-41004',
    _41005 = '07-41005',
    _41006 = '07-41006',
    _41007 = '07-41007',
    _41008 = '07-41008',
    _41009 = '07-41009',
    _41010 = '07-41010',
    _41011 = '07-41011',
    _41012 = '07-41012',
    _41013 = '07-41013',
    _41014 = '07-41014',
    _41015 = '07-41015',
    _41016 = '07-41016',
    _41017 = '07-41017',
    _41018 = '07-41018',
    _41019 = '07-41019',
    _41020 = '07-41020',
    _41021 = '07-41021',
    _41022 = '07-41022',
    _41023 = '07-41023',
    _41024 = '07-41024',
    _41025 = '07-41025',
    _51000 = '07-51000',
    _51001 = '07-51001',
    _51002 = '07-51002',
    _51003 = '07-51003',
    _51004 = '07-51004',
    _51005 = '07-51005',
    _51006 = '07-51006',
    _51007 = '07-51007',
    _51008 = '07-51008',
    _51011 = '07-51011',
    _51012 = '07-51012',
    _51013 = '07-51013',
    _51014 = '07-51014',
    _51015 = '07-51015',
    _51016 = '07-51016',
    _51017 = '07-51017',
    _51018 = '07-51018',
    _51019 = '07-51019',
    _51020 = '07-51020',
    _51021 = '07-51021',
    _51022 = '07-51022',
    _51023 = '07-51023',
    _51024 = '07-51024',
    _51025 = '07-51025',
    _51026 = '07-51026',
    _51027 = '07-51027',
    _51028 = '07-51028',
    _51029 = '07-51029',
    _61000 = '07-61000',
    _61001 = '07-61001',
    _61002 = '07-61002',
    _61003 = '07-61003',
    _61004 = '07-61004',
    _61005 = '07-61005',
    _71000 = '07-71000',
    _71001 = '07-71001',
    _71002 = '07-71002',
    _71003 = '07-71003',
    _81000 = '07-81000',
    _81001 = '07-81001',
    _81002 = '07-81002',
    _81003 = '07-81003',
    _81004 = '07-81004'
}

export function ValidationErrorCodeFromJSON(json: any): ValidationErrorCode {
    return ValidationErrorCodeFromJSONTyped(json, false);
}

export function ValidationErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationErrorCode {
    return json as ValidationErrorCode;
}

export function ValidationErrorCodeToJSON(value?: ValidationErrorCode | null): any {
    return value as any;
}

