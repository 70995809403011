/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { FormElementLinkType } from './FormElementLinkType';
import {
    FormElementLinkTypeFromJSON,
    FormElementLinkTypeFromJSONTyped,
    FormElementLinkTypeToJSON,
} from './FormElementLinkType';
import type { FormElementType } from './FormElementType';
import {
    FormElementTypeFromJSON,
    FormElementTypeFromJSONTyped,
    FormElementTypeToJSON,
} from './FormElementType';
import type { LinkAllOf } from './LinkAllOf';
import {
    LinkAllOfFromJSON,
    LinkAllOfFromJSONTyped,
    LinkAllOfToJSON,
} from './LinkAllOf';

/**
 * 
 * @export
 * @interface Link
 */
export interface Link extends FormElement {
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    newEvent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    openEvents?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    closedEvents?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    archivedDocs?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    draftDocs?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    releasedDocs?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    showHintText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    hintText?: string;
    /**
     * 
     * @type {FormElementLinkType}
     * @memberof Link
     */
    linkType?: FormElementLinkType;
}

export function LinkFromJSON(json: any): Link {
    return LinkFromJSONTyped(json, false);
}

export function LinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): Link {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormElementFromJSONTyped(json, ignoreDiscriminator),
        'newEvent': !exists(json, 'newEvent') ? undefined : json['newEvent'],
        'openEvents': !exists(json, 'openEvents') ? undefined : json['openEvents'],
        'closedEvents': !exists(json, 'closedEvents') ? undefined : json['closedEvents'],
        'archivedDocs': !exists(json, 'archivedDocs') ? undefined : json['archivedDocs'],
        'draftDocs': !exists(json, 'draftDocs') ? undefined : json['draftDocs'],
        'releasedDocs': !exists(json, 'releasedDocs') ? undefined : json['releasedDocs'],
        'showHintText': !exists(json, 'showHintText') ? undefined : json['showHintText'],
        'hintText': !exists(json, 'hintText') ? undefined : json['hintText'],
        'linkType': !exists(json, 'linkType') ? undefined : FormElementLinkTypeFromJSON(json['linkType']),
    };
}

export function LinkToJSON(value?: Link | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormElementToJSON(value),
        'newEvent': value.newEvent,
        'openEvents': value.openEvents,
        'closedEvents': value.closedEvents,
        'archivedDocs': value.archivedDocs,
        'draftDocs': value.draftDocs,
        'releasedDocs': value.releasedDocs,
        'showHintText': value.showHintText,
        'hintText': value.hintText,
        'linkType': FormElementLinkTypeToJSON(value.linkType),
    };
}

