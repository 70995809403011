import { AuthResult } from "@mc/api";

const LOCAL_STORAGE_KEY = "@mc/auth";

export const updateAuthLocalStorage = (newKeyValue: Record<string, string | null | undefined>) => {
    const local = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    const existing = local ? JSON.parse(local) : {};

    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ ...existing, ...newKeyValue }));
};

export const updateLocalStorageWithToken = ({ mcToken, pcsSessionId }: AuthResult) =>
    updateAuthLocalStorage({ mcToken, pcsSessionId });

/**
 * Retrieves a value from the "@mc/auth" local storage object, given a key.
 */
export const getAuthLocalStorageValue = (key: string): string | null => {
    const authValues = window.localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!authValues) {
        return null;
    }

    const authObject = JSON.parse(authValues);
    return authObject?.[key] ?? null;
};

export const getLoginUrl = () => {
    const baseUrl = getAuthLocalStorageValue("baseUrl");

    if (!baseUrl) {
        return null;
    }

    return `${baseUrl}/login/index.cfm`;
};

export const getLogoutUrl = () => {
    const baseUrl = getAuthLocalStorageValue("baseUrl");

    if (!baseUrl) {
        return null;
    }

    return `${baseUrl}/authentication/logout`;
};

export const getMyMasterControlLink = () => {
    const baseUrl = getAuthLocalStorageValue("baseUrl");

    if (!baseUrl) {
        return null;
    }

    return `${baseUrl}/index.cfm`;
};

export const getUserManagementLink = () => {
    const baseUrl = getAuthLocalStorageValue("baseUrl");

    if (!baseUrl) {
        return null;
    }

    return `${baseUrl}/index.cfm#/portal/users`;
};

export const getHubsPortalLink = () => {
    const baseUrl = getAuthLocalStorageValue("baseUrl");

    if (!baseUrl) {
        return null;
    }

    return `${baseUrl}/index.cfm#/hubs/portal`;
};

export const getInsightsLink = () => {
    const baseUrl = getAuthLocalStorageValue("baseUrl");

    if (!baseUrl) {
        return null;
    }

    return `${baseUrl}/index.cfm#/insights`;
};

export const isQxFlow = () => {
    const baseUrl = getAuthLocalStorageValue("baseUrl");

    return !!baseUrl;
};

export const removeAuthLocalStorageValue = () => window.localStorage.removeItem(LOCAL_STORAGE_KEY);
