/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttachFileRequest,
    AttachFileRequestFromJSON,
    AttachFileRequestToJSON,
    Attachment,
    AttachmentFromJSON,
    AttachmentToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    UnattachFileRequest,
    UnattachFileRequestFromJSON,
    UnattachFileRequestToJSON,
} from '../models';

export interface AttachFileOperationRequest {
    attachFileRequest: AttachFileRequest;
}

export interface GetAttachmentRequest {
    attachmentId: string;
}

export interface GetTaskAttachmentsRequest {
    elementId: string;
}

export interface UnattachFileOperationRequest {
    attachmentId: string;
    unattachFileRequest: UnattachFileRequest;
}

/**
 * 
 */
export class AttachmentApi extends runtime.BaseAPI {

    /**
     * Attach a file to a record. License required is Full or Basic.
     * Attach a file to a record.
     */
    async attachFileRaw(requestParameters: AttachFileOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Attachment>> {
        if (requestParameters.attachFileRequest === null || requestParameters.attachFileRequest === undefined) {
            throw new runtime.RequiredError('attachFileRequest','Required parameter requestParameters.attachFileRequest was null or undefined when calling attachFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/attachment/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttachFileRequestToJSON(requestParameters.attachFileRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFromJSON(jsonValue));
    }

    /**
     * Attach a file to a record. License required is Full or Basic.
     * Attach a file to a record.
     */
    async attachFile(requestParameters: AttachFileOperationRequest, initOverrides?: RequestInit): Promise<Attachment> {
        const response = await this.attachFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get an attachment by attachment id
     */
    async getAttachmentRaw(requestParameters: GetAttachmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Attachment>> {
        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling getAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/attachment/v1/{attachmentId}`.replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFromJSON(jsonValue));
    }

    /**
     * get an attachment by attachment id
     */
    async getAttachment(requestParameters: GetAttachmentRequest, initOverrides?: RequestInit): Promise<Attachment> {
        const response = await this.getAttachmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get attachments on an element. License required is Full or Basic.
     * Get attachments on an element
     */
    async getTaskAttachmentsRaw(requestParameters: GetTaskAttachmentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Attachment>>> {
        if (requestParameters.elementId === null || requestParameters.elementId === undefined) {
            throw new runtime.RequiredError('elementId','Required parameter requestParameters.elementId was null or undefined when calling getTaskAttachments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/element/v1/{elementId}/attachments`.replace(`{${"elementId"}}`, encodeURIComponent(String(requestParameters.elementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentFromJSON));
    }

    /**
     * Get attachments on an element. License required is Full or Basic.
     * Get attachments on an element
     */
    async getTaskAttachments(requestParameters: GetTaskAttachmentsRequest, initOverrides?: RequestInit): Promise<Array<Attachment>> {
        const response = await this.getTaskAttachmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unattach a file to a record. License required is Full or Basic.
     * Remove attachment from record.
     */
    async unattachFileRaw(requestParameters: UnattachFileOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling unattachFile.');
        }

        if (requestParameters.unattachFileRequest === null || requestParameters.unattachFileRequest === undefined) {
            throw new runtime.RequiredError('unattachFileRequest','Required parameter requestParameters.unattachFileRequest was null or undefined when calling unattachFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/attachment/v1/{attachmentId}`.replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UnattachFileRequestToJSON(requestParameters.unattachFileRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unattach a file to a record. License required is Full or Basic.
     * Remove attachment from record.
     */
    async unattachFile(requestParameters: UnattachFileOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.unattachFileRaw(requestParameters, initOverrides);
    }

}
