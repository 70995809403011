/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DirectCorrection } from './DirectCorrection';
import {
    DirectCorrectionFromJSON,
    DirectCorrectionFromJSONTyped,
    DirectCorrectionToJSON,
} from './DirectCorrection';
import type { ElementData } from './ElementData';
import {
    ElementDataFromJSON,
    ElementDataFromJSONTyped,
    ElementDataToJSON,
} from './ElementData';
import type { ExecutionPlanElement } from './ExecutionPlanElement';
import {
    ExecutionPlanElementFromJSON,
    ExecutionPlanElementFromJSONTyped,
    ExecutionPlanElementToJSON,
} from './ExecutionPlanElement';
import type { ExecutionPlanNode } from './ExecutionPlanNode';
import {
    ExecutionPlanNodeFromJSON,
    ExecutionPlanNodeFromJSONTyped,
    ExecutionPlanNodeToJSON,
} from './ExecutionPlanNode';
import type { ExecutionPlanSection } from './ExecutionPlanSection';
import {
    ExecutionPlanSectionFromJSON,
    ExecutionPlanSectionFromJSONTyped,
    ExecutionPlanSectionToJSON,
} from './ExecutionPlanSection';
import type { Record } from './Record';
import {
    RecordFromJSON,
    RecordFromJSONTyped,
    RecordToJSON,
} from './Record';
import type { Signature } from './Signature';
import {
    SignatureFromJSON,
    SignatureFromJSONTyped,
    SignatureToJSON,
} from './Signature';

/**
 * 
 * @export
 * @interface PartialRecordDetailView
 */
export interface PartialRecordDetailView {
    /**
     * 
     * @type {Array<ExecutionPlanSection>}
     * @memberof PartialRecordDetailView
     */
    sections?: Array<ExecutionPlanSection>;
    /**
     * 
     * @type {Array<ExecutionPlanElement>}
     * @memberof PartialRecordDetailView
     */
    elements?: Array<ExecutionPlanElement>;
    /**
     * 
     * @type {Array<ElementData>}
     * @memberof PartialRecordDetailView
     */
    elementDatas?: Array<ElementData>;
    /**
     * 
     * @type {Array<ExecutionPlanNode>}
     * @memberof PartialRecordDetailView
     */
    nodes?: Array<ExecutionPlanNode>;
    /**
     * 
     * @type {Array<DirectCorrection>}
     * @memberof PartialRecordDetailView
     */
    directCorrections?: Array<DirectCorrection>;
    /**
     * 
     * @type {Record}
     * @memberof PartialRecordDetailView
     */
    record?: Record;
    /**
     * 
     * @type {Array<Signature>}
     * @memberof PartialRecordDetailView
     */
    signatures?: Array<Signature>;
}

export function PartialRecordDetailViewFromJSON(json: any): PartialRecordDetailView {
    return PartialRecordDetailViewFromJSONTyped(json, false);
}

export function PartialRecordDetailViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartialRecordDetailView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sections': !exists(json, 'sections') ? undefined : (json['sections'] as Array<ExecutionPlanSection>).map(ExecutionPlanSectionFromJSON),
        'elements': !exists(json, 'elements') ? undefined : (json['elements'] as Array<ExecutionPlanElement>).map(ExecutionPlanElementFromJSON),
        'elementDatas': !exists(json, 'elementDatas') ? undefined : (json['elementDatas'] as Array<ElementData>).map(ElementDataFromJSON),
        'nodes': !exists(json, 'nodes') ? undefined : (json['nodes'] as Array<ExecutionPlanNode>).map(ExecutionPlanNodeFromJSON),
        'directCorrections': !exists(json, 'directCorrections') ? undefined : (json['directCorrections'] as Array<DirectCorrection>).map(DirectCorrectionFromJSON),
        'record': !exists(json, 'record') ? undefined : RecordFromJSON(json['record']),
        'signatures': !exists(json, 'signatures') ? undefined : (json['signatures'] as Array<Signature>).map(SignatureFromJSON),
    };
}

export function PartialRecordDetailViewToJSON(value?: PartialRecordDetailView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sections': value.sections === undefined ? undefined : (value.sections as Array<ExecutionPlanSection>).map(ExecutionPlanSectionToJSON),
        'elements': value.elements === undefined ? undefined : (value.elements as Array<ExecutionPlanElement>).map(ExecutionPlanElementToJSON),
        'elementDatas': value.elementDatas === undefined ? undefined : (value.elementDatas as Array<ElementData>).map(ElementDataToJSON),
        'nodes': value.nodes === undefined ? undefined : (value.nodes as Array<ExecutionPlanNode>).map(ExecutionPlanNodeToJSON),
        'directCorrections': value.directCorrections === undefined ? undefined : (value.directCorrections as Array<DirectCorrection>).map(DirectCorrectionToJSON),
        'record': RecordToJSON(value.record),
        'signatures': value.signatures === undefined ? undefined : (value.signatures as Array<Signature>).map(SignatureToJSON),
    };
}

