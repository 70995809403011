import { applicationConfig, i18nFormatterFunctions } from "@mc/common";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import resources from "virtual:i18next-loader";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: !applicationConfig.production,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, // react already escapes values to prevent xss
        },
        resources,
    });

i18nFormatterFunctions.map((func) => i18n.services.formatter?.add(func.name, func));
