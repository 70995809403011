/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DueDateExtensionReasonEnum } from './DueDateExtensionReasonEnum';
import {
    DueDateExtensionReasonEnumFromJSON,
    DueDateExtensionReasonEnumFromJSONTyped,
    DueDateExtensionReasonEnumToJSON,
} from './DueDateExtensionReasonEnum';
import type { DueDateExtensionStatusEnum } from './DueDateExtensionStatusEnum';
import {
    DueDateExtensionStatusEnumFromJSON,
    DueDateExtensionStatusEnumFromJSONTyped,
    DueDateExtensionStatusEnumToJSON,
} from './DueDateExtensionStatusEnum';
import type { DueDateExtensionTargetTypeEnum } from './DueDateExtensionTargetTypeEnum';
import {
    DueDateExtensionTargetTypeEnumFromJSON,
    DueDateExtensionTargetTypeEnumFromJSONTyped,
    DueDateExtensionTargetTypeEnumToJSON,
} from './DueDateExtensionTargetTypeEnum';

/**
 * 
 * @export
 * @interface DueDateExtension
 */
export interface DueDateExtension {
    /**
     * 
     * @type {string}
     * @memberof DueDateExtension
     */
    id?: string;
    /**
     * The record that this due date is associated with; in the case of targetType ACTION_ITEM, this is the action item record.
     * @type {string}
     * @memberof DueDateExtension
     */
    recordId?: string;
    /**
     * The node that this due date is associated with; in the case of targetType NODE, the due date belongs to the task that belongs to this node.
     * @type {string}
     * @memberof DueDateExtension
     */
    nodeId?: string;
    /**
     * 
     * @type {DueDateExtensionTargetTypeEnum}
     * @memberof DueDateExtension
     */
    targetType?: DueDateExtensionTargetTypeEnum;
    /**
     * 
     * @type {DueDateExtensionReasonEnum}
     * @memberof DueDateExtension
     */
    reason?: DueDateExtensionReasonEnum;
    /**
     * 
     * @type {DueDateExtensionStatusEnum}
     * @memberof DueDateExtension
     */
    status?: DueDateExtensionStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof DueDateExtension
     */
    originalDueDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DueDateExtension
     */
    requestedDueDate?: Date;
    /**
     * Approved extension date
     * @type {Date}
     * @memberof DueDateExtension
     */
    approverAdjustedDueDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DueDateExtension
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof DueDateExtension
     */
    tenantId?: string;
    /**
     * The user id who requests the due date extension
     * @type {string}
     * @memberof DueDateExtension
     */
    createdBy?: string;
    /**
     * The user id who approves/denies/cancels the due date extension request
     * @type {string}
     * @memberof DueDateExtension
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DueDateExtension
     */
    createdAt?: Date;
    /**
     * The approved/denied/cancelled time of the due date extension
     * @type {Date}
     * @memberof DueDateExtension
     */
    modifiedAt?: Date | null;
}

export function DueDateExtensionFromJSON(json: any): DueDateExtension {
    return DueDateExtensionFromJSONTyped(json, false);
}

export function DueDateExtensionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DueDateExtension {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'recordId': !exists(json, 'recordId') ? undefined : json['recordId'],
        'nodeId': !exists(json, 'nodeId') ? undefined : json['nodeId'],
        'targetType': !exists(json, 'targetType') ? undefined : DueDateExtensionTargetTypeEnumFromJSON(json['targetType']),
        'reason': !exists(json, 'reason') ? undefined : DueDateExtensionReasonEnumFromJSON(json['reason']),
        'status': !exists(json, 'status') ? undefined : DueDateExtensionStatusEnumFromJSON(json['status']),
        'originalDueDate': !exists(json, 'originalDueDate') || json['originalDueDate'] === '' ? undefined : new Date(json['originalDueDate']),
        'requestedDueDate': !exists(json, 'requestedDueDate') || json['requestedDueDate'] === '' ? undefined : new Date(json['requestedDueDate']),
        'approverAdjustedDueDate': !exists(json, 'approverAdjustedDueDate') || json['approverAdjustedDueDate'] === '' ? undefined : json['approverAdjustedDueDate'] === null ? null : new Date(json['approverAdjustedDueDate']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'createdAt': !exists(json, 'createdAt') || json['createdAt'] === '' ? undefined : new Date(json['createdAt']),
        'modifiedAt': !exists(json, 'modifiedAt') || json['modifiedAt'] === '' ? undefined : json['modifiedAt'] === null ? null : new Date(json['modifiedAt']),
    };
}

export function DueDateExtensionToJSON(value?: DueDateExtension | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'recordId': value.recordId,
        'nodeId': value.nodeId,
        'targetType': DueDateExtensionTargetTypeEnumToJSON(value.targetType),
        'reason': DueDateExtensionReasonEnumToJSON(value.reason),
        'status': DueDateExtensionStatusEnumToJSON(value.status),
        'originalDueDate': value.originalDueDate === undefined ? undefined : value.originalDueDate.toISOString(),
        'requestedDueDate': value.requestedDueDate === undefined ? undefined : value.requestedDueDate.toISOString(),
        'approverAdjustedDueDate': value.approverAdjustedDueDate === undefined ? undefined : value.approverAdjustedDueDate === null ? null : value.approverAdjustedDueDate.toISOString(),
        'comments': value.comments,
        'tenantId': value.tenantId,
        'createdBy': value.createdBy,
        'modifiedBy': value.modifiedBy,
        'createdAt': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'modifiedAt': value.modifiedAt === undefined ? undefined : value.modifiedAt === null ? null : value.modifiedAt.toISOString(),
    };
}

