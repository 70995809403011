/* tslint:disable */
/* eslint-disable */
/**
 * Validation on Demand OpenAPI Definition
 * Validation on Demand negotiated endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface PcsValidationOnDemandPcsValidationsV1ValidationPlanIdReportLanguageGetRequest {
    validationPlanId: string;
    language: PcsValidationOnDemandPcsValidationsV1ValidationPlanIdReportLanguageGetLanguageEnum;
}

/**
 * 
 */
export class PcsReportApi extends runtime.BaseAPI {

    /**
     * endpoint to receive the report for a validation plan
     * get a validation plan report. This is guarded by a FULL/BASIC token.
     */
    async pcsValidationOnDemandPcsValidationsV1ValidationPlanIdReportLanguageGetRaw(requestParameters: PcsValidationOnDemandPcsValidationsV1ValidationPlanIdReportLanguageGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.validationPlanId === null || requestParameters.validationPlanId === undefined) {
            throw new runtime.RequiredError('validationPlanId','Required parameter requestParameters.validationPlanId was null or undefined when calling pcsValidationOnDemandPcsValidationsV1ValidationPlanIdReportLanguageGet.');
        }

        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling pcsValidationOnDemandPcsValidationsV1ValidationPlanIdReportLanguageGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/validation-on-demand/pcs/validations/v1/{validationPlanId}/report/{language}`.replace(`{${"validationPlanId"}}`, encodeURIComponent(String(requestParameters.validationPlanId))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * endpoint to receive the report for a validation plan
     * get a validation plan report. This is guarded by a FULL/BASIC token.
     */
    async pcsValidationOnDemandPcsValidationsV1ValidationPlanIdReportLanguageGet(requestParameters: PcsValidationOnDemandPcsValidationsV1ValidationPlanIdReportLanguageGetRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.pcsValidationOnDemandPcsValidationsV1ValidationPlanIdReportLanguageGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum PcsValidationOnDemandPcsValidationsV1ValidationPlanIdReportLanguageGetLanguageEnum {
    EnUs = 'en_US',
    ZhCn = 'zh_CN',
    ZhTw = 'zh_TW',
    FrFr = 'fr_FR',
    DeDe = 'de_DE',
    ItIt = 'it_IT',
    JaJp = 'ja_JP',
    KoKr = 'ko_KR',
    PtPt = 'pt_PT',
    EsEs = 'es_ES',
    ViVn = 'vi_VN',
    RuRu = 'ru_RU'
}
