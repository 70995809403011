/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DurationUnit } from './DurationUnit';
import {
    DurationUnitFromJSON,
    DurationUnitFromJSONTyped,
    DurationUnitToJSON,
} from './DurationUnit';
import type { VodPlanStatus } from './VodPlanStatus';
import {
    VodPlanStatusFromJSON,
    VodPlanStatusFromJSONTyped,
    VodPlanStatusToJSON,
} from './VodPlanStatus';
import type { WorkflowStatus } from './WorkflowStatus';
import {
    WorkflowStatusFromJSON,
    WorkflowStatusFromJSONTyped,
    WorkflowStatusToJSON,
} from './WorkflowStatus';

/**
 * Workflow
 * @export
 * @interface Workflow
 */
export interface Workflow {
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    seriesId: string;
    /**
     * ID of the application the Workflow belongs to
     * @type {string}
     * @memberof Workflow
     */
    appId: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    sourceTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Workflow
     */
    revision: number;
    /**
     * 
     * @type {WorkflowStatus}
     * @memberof Workflow
     */
    status: WorkflowStatus;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    numberingPrefix: string;
    /**
     * 
     * @type {number}
     * @memberof Workflow
     */
    duration?: number;
    /**
     * 
     * @type {DurationUnit}
     * @memberof Workflow
     */
    durationUnit?: DurationUnit;
    /**
     * 
     * @type {boolean}
     * @memberof Workflow
     */
    durationInBusinessDays?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Workflow
     */
    excludeRecordsFromGeneralLaunch?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Workflow
     */
    excludeRecordsFromGlobalSearch?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Workflow
     */
    excludeRecordsFromMyCollection?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workflow
     */
    userIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workflow
     */
    groupIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workflow
     */
    sites?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Workflow
     */
    allSites?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Workflow
     */
    isValidated?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Workflow
     */
    validationResetDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Workflow
     */
    requireValidation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    validationJustification?: string;
    /**
     * 
     * @type {VodPlanStatus}
     * @memberof Workflow
     */
    lastValidationPlanStatus?: VodPlanStatus;
    /**
     * 
     * @type {Date}
     * @memberof Workflow
     */
    createdDate: Date;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    createdBy: string;
    /**
     * 
     * @type {Date}
     * @memberof Workflow
     */
    lastModifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    lastModifiedBy?: string;
}

export function WorkflowFromJSON(json: any): Workflow {
    return WorkflowFromJSONTyped(json, false);
}

export function WorkflowFromJSONTyped(json: any, ignoreDiscriminator: boolean): Workflow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'seriesId': json['seriesId'],
        'appId': json['appId'],
        'sourceTemplateId': !exists(json, 'sourceTemplateId') ? undefined : json['sourceTemplateId'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'revision': json['revision'],
        'status': WorkflowStatusFromJSON(json['status']),
        'type': json['type'],
        'numberingPrefix': json['numberingPrefix'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'durationUnit': !exists(json, 'durationUnit') ? undefined : DurationUnitFromJSON(json['durationUnit']),
        'durationInBusinessDays': !exists(json, 'durationInBusinessDays') ? undefined : json['durationInBusinessDays'],
        'excludeRecordsFromGeneralLaunch': !exists(json, 'excludeRecordsFromGeneralLaunch') ? undefined : json['excludeRecordsFromGeneralLaunch'],
        'excludeRecordsFromGlobalSearch': !exists(json, 'excludeRecordsFromGlobalSearch') ? undefined : json['excludeRecordsFromGlobalSearch'],
        'excludeRecordsFromMyCollection': !exists(json, 'excludeRecordsFromMyCollection') ? undefined : json['excludeRecordsFromMyCollection'],
        'userIds': !exists(json, 'userIds') ? undefined : (json['userIds'] as Array<string>),
        'groupIds': !exists(json, 'groupIds') ? undefined : (json['groupIds'] as Array<string>),
        'sites': !exists(json, 'sites') ? undefined : (json['sites'] as Array<string>),
        'allSites': !exists(json, 'allSites') ? undefined : json['allSites'],
        'isValidated': !exists(json, 'isValidated') ? undefined : json['isValidated'],
        'validationResetDate': !exists(json, 'validationResetDate') || json['validationResetDate'] === '' ? undefined : new Date(json['validationResetDate']),
        'requireValidation': !exists(json, 'requireValidation') ? undefined : json['requireValidation'],
        'validationJustification': !exists(json, 'validationJustification') ? undefined : json['validationJustification'],
        'lastValidationPlanStatus': !exists(json, 'lastValidationPlanStatus') ? undefined : VodPlanStatusFromJSON(json['lastValidationPlanStatus']),
        'createdDate': new Date(json['createdDate']),
        'createdBy': json['createdBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') || json['lastModifiedDate'] === '' ? undefined : new Date(json['lastModifiedDate']),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
    };
}

export function WorkflowToJSON(value?: Workflow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'seriesId': value.seriesId,
        'appId': value.appId,
        'sourceTemplateId': value.sourceTemplateId,
        'name': value.name,
        'description': value.description,
        'revision': value.revision,
        'status': WorkflowStatusToJSON(value.status),
        'type': value.type,
        'numberingPrefix': value.numberingPrefix,
        'duration': value.duration,
        'durationUnit': DurationUnitToJSON(value.durationUnit),
        'durationInBusinessDays': value.durationInBusinessDays,
        'excludeRecordsFromGeneralLaunch': value.excludeRecordsFromGeneralLaunch,
        'excludeRecordsFromGlobalSearch': value.excludeRecordsFromGlobalSearch,
        'excludeRecordsFromMyCollection': value.excludeRecordsFromMyCollection,
        'userIds': value.userIds === undefined ? undefined : (value.userIds as Array<string>),
        'groupIds': value.groupIds === undefined ? undefined : (value.groupIds as Array<string>),
        'sites': value.sites === undefined ? undefined : (value.sites as Array<string>),
        'allSites': value.allSites,
        'isValidated': value.isValidated,
        'validationResetDate': value.validationResetDate === undefined ? undefined : value.validationResetDate.toISOString(),
        'requireValidation': value.requireValidation,
        'validationJustification': value.validationJustification,
        'lastValidationPlanStatus': VodPlanStatusToJSON(value.lastValidationPlanStatus),
        'createdDate': value.createdDate.toISOString(),
        'createdBy': value.createdBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : value.lastModifiedDate.toISOString(),
        'lastModifiedBy': value.lastModifiedBy,
    };
}

