import { buildUriRegexCollection } from "@mc/common";

export const ApiUris = {
    amx: {
        getRecord: "/pcs/amx-app-service/record/v1/{id}",
    },
    auditTrail: {
        /** @deprecated The endpoint to call getAuditableEvents is not longer available */
        getAuditableEvents: "/pcs/audit-trail/events/v1",
        getAuditHistoryEvents: "/pcs/audit-trail/history/v1/domain/{domain}/rootAggregateId/{rootAggregateId}",
        getAuditHistoryEventsByAssociatedAggregateId:
            "/pcs/audit-trail/history/v1/domain/{domain}/associatedAggregateId/{associatedAggregateId}",

        // FIXME: aqem BFF URIs should go into the AQEM shared folder apps\aqem\shared\src\uris.ts
        // eslint-disable-next-line sort-keys
        getAuditHistory: "/api/aqem/audit/v1/:domain/{rootAggregateId}/history",
        getAuditHistoryStats: "/api/aqem/audit/v1/:domain/{rootAggregateId}/stats",
        getS2SToken: "/pcs/audit-trail/v1/oauth2/token",
        postAuditableEvent: "/pcs/audit-trail/events/v1",
    },
    cache: {
        addGetAndDeleteFromCache: "/pcs/cache/v1/{productId}/{tenantId}/{cacheKey}",
        getS2SToken: "/pcs/cache/v1/oauth2/token",
    },
    eSignature: {
        validateESig: "/pcs/qx-signature/v1/user/{qxDbUsername}/validate",
    },
    file: {
        deleteFile: "/pcs/file-service/file/v1",
        getFilesByFolderId: "/pcs/file-service/files/v1/folder/{folderId}",
        getFileScanStatus: "/pcs/file-service/file/v1/scan/status",
        mockPresignedUrl: "https://fakepresignedurl-quarantine.amazonaws.com",
        presignedFileUrl: "/pcs/file-service/presigned-url/v1",
    },
    identity: {
        license: {
            getLicensesForUser: "/pcs/identity/user/v1/licenses/{userId}",
            listProductLicenseCount: "/pcs/identity/licenses/v1",
            putLogbooksLicense: "/pcs/identity/licenses/v1/Logbooks{userId}?licenseType={licenseType}",
            updateUserProductLicense: "/pcs/identity/license/v1/{product}/{userId}",
        },
        metadata: {
            get: "/pcs/identity/metadata/v1/{ids}",
        },
        user: {
            getAvatarUsers: "/pcs/identity/users/v1/avatar",
            getUserEmail: "/pcs/identity/users/v1/email",
            getUserIdFromQxDbUsername: "/api/logbooks/qx-identity/{qxDbUsername}",
            getUsers: "/pcs/identity/users/v1",
            searchUsersAndMetadata: "/pcs/identity/users/v2/search",
            searchUsersAndRoles: "/pcs/identity/users/v1/search",
            updateIsSysadmin: "/pcs/identity/user/v1/{userId}/sysadmin",
        },
        userMetadata: {
            create: "/pcs/identity/metadata/v1",
            criteriaUserMetadataSearch: "/pcs/identity/users/v1/metadata/action/criteria-search",
            findUsersByMetadata: "/pcs/identity/users/v1/metadata/action/search",
            get: "/pcs/identity/metadata/v1/{metadataId}",
            linkMetadataToUsers: "/pcs/identity/users/v1/metadata/action/link",
            listMetadata: "/pcs/identity/metadata/v1/key/{metadataKey}",
            listUserIdsForMetadataIds: "/pcs/identity/users/v1/metadata/{metadataIds}",
            unlinkMetadataToUsers: "/pcs/identity/users/v1/metadata/action/unlink",
        },
    },
    list: {
        v1: {
            checkUniqueName: "/pcs/list-service/pcs-list/v1/action/check-name",
            createPcsList: "/pcs/list-service/pcs-list/v1",
            getAllPcsLists: "/pcs/list-service/pcs-list/v1",
            getPcsList: "/pcs/list-service/pcs-list/v1/{id}",
            getPcsListsByIds: "/pcs/list-service/pcs-list/v1/lists",
            searchPcsLists: "/pcs/list-service/pcs-list/v1/action/search",
            updatePcsList: "/pcs/list-service/pcs-list/v1/{id}",
        },
        v2: {
            getPcsList: "/pcs/list-service/pcs-list/v2/{listId}",
            getPcsListItems: "/pcs/list-service/pcs-list/v2/{id}/items",
            getPcsListsByIds: "/pcs/list-service/pcs-list/v2/lists",
            searchPcsListItems: "/pcs/list-service/pcs-list/v2/action/search/{id}",
        },
    },
    notification: {
        createNotification: "/pcs/notification/notification/v1",
        emailUnsubscribe: "/pcs/notification/notification/v1/email/action/unsubscribe",
        getActiveReminders: "/pcs/notification/notification/v1/activeReminders",
        getNotification: "/pcs/notification/notification/v1/{notificationId}",
        getNotificationCount: "/pcs/notification/notification/v1/count",
        getUserNotifications: "/pcs/notification/notifications/v1",
        pollUpdates: "/pcs/notification/notification/v1/updates",
        resetActiveReminder: "/pcs/notification/notification/v1/{notificationId}/activeReminder/action/reset",
        setReminder: "/pcs/notification/notification/v1/{notificationId}/reminder",
        updateStatus: "/pcs/notification/notification/v1/{notificationId}/status",
        updateStatusForAllNotifications: "/pcs/notification/notification/v1/status",
    },
    okta: {
        authN: "/api/v1/authn",
        authorize: "oauth2/default/v1/authorize",
    },
    qxSignature: {
        validateEsig: "/pcs/qx-signature/v1/user/{qxDbUsername}/validate",
    },
    recordConfiguration: {
        approval: {
            v1: {
                addApproval: "/pcs/workflow/approval/v1",
                removeApproval: "/pcs/workflow/approval/v1/{approvalId}",
                updateApproval: "/pcs/workflow/approval/v1/{approvalId}",
            },
        },
        bff: {
            permissions: {
                // FIXME: aqem BFF URIs should go into the AQEM shared folder apps\aqem\shared\src\uris.ts
                updateCondition: "/api/aqem/permissions/v1/{conditionId}/permissionCondition",
            },
            workflow: {
                // FIXME: aqem BFF URIs should go into the AQEM shared folder apps\aqem\shared\src\uris.ts
                archiveWorkflow: "/api/aqem/workflow/v1/{workflowId}/action/archive",
                publishWorkflow: "/api/aqem/workflow/v1/{workflowId}/actions/publish",
                runVod: "/api/aqem/workflow/v1/{workflowId}/actions/validate-vod",
                validateWorkflow: "/api/aqem/workflow/v1/{workflowId}/actions/validate",
            },
        },
        formElement: {
            v1: {
                addFormElement: "/pcs/form/form-element/v1",
                addSelectionOption: "/pcs/form/form-element/v1/{formElementId}/option",
                copyFormelement: "/pcs/form/form-element/v1/{formElementId}/action/copy",
                moveFormElement: "/pcs/form/form-element/v1/{formElementId}/action/move",
                moveSelectionOtion: "/pcs/form/form-element/v1/{formElementId}/option/{optionId}/action/move",
                updateOrRemoveFormElement: "/pcs/form/form-element/v1/{formElementId}",
                updateOrRemoveSelectionOption: "/pcs/form/form-element/v1/{formElementId}/option/{optionId}",
            },
            v2: {
                addFormElement: "/pcs/form/form-element/v2",
                addSelectionOption: "/pcs/form/form-element/v2/{formElementId}/option",
                copyFormelement: "/pcs/form/form-element/v2/{formElementId}/action/copy",
                moveFormElement: "/pcs/form/form-element/v2/{formElementId}/action/move",
                moveSelectionOption: "/pcs/form/form-element/v2/{formElementId}/option/{optionId}/action/move",
                updateOrRemoveFormElement: "/pcs/form/form-element/v2/{formElementId}",
                updateOrRemoveSelectionOption: "/pcs/form/form-element/v2/{formElementId}/option/{optionId}",
            },
        },
        list: {
            v1: {
                getListBreadcrumbsV1: "/pcs/workflow/list/v1/breadcrumbs/{listId}",
                getListCountsV1: "/pcs/workflow/list/v1/counts",
            },
        },
        node: {
            addNode: "/pcs/workflow/workflow/v1/{workflowId}/node",
            movePath: "/pcs/workflow/node/v1/action/movePath",
            removeNodes: "/pcs/workflow/nodes/v1",
            updateOrRemoveNode: "/pcs/workflow/node/v1/{nodeId}",
        },
        rule: {
            addPermissionCondition: "/pcs/rule/rule/v1/{ruleId}/permissionCondition",
            checkRules: "/pcs/rule/rule/v1/{workflowId}/action/checkRules",
            createRule: "/pcs/rule/rule/v1",
            getRulesAndTargetObjectsV1: "/pcs/rule/rule/v1",
            getValidTargetNodes: "/pcs/rule/rule/v1/validTargetNodes",
            getValidTargetSections: "/pcs/rule/rule/v1/validTargetSections",
            removeCondition: "/pcs/rule/rule/v1/{conditionId}/condition",
            removeRule: "/pcs/rule/rule/v1/{ruleId}",
        },
        section: {
            v1: {
                addSection: "/pcs/form/section/v1",
                getSections: "/pcs/form/sections/v1",
                updateOrRemoveSection: "/pcs/form/section/v1/{sectionId}",
            },
            v2: {
                addSection: "/pcs/form/section/v2",
                getSections: "/pcs/form/sections/v2",
                moveSection: "/pcs/form/section/v2/{sectionId}/action/move",
                updateOrRemoveSection: "/pcs/form/section/v2/{sectionId}",
            },
        },
        tag: {
            getTagBreadcrumbs: "/pcs/workflow/tag/v1/breadcrumbs/{tagId}",
        },
        template: {
            v1: {
                createTemplate: "/pcs/workflow/template/v1",
                createWorkflowFromSolutionTemplate: "/pcs/workflow/template/solution/v1",
                getSolutionTemplatePackageById: "/pcs/workflow/template/solution/v1/{templateId}/package",
                getSolutionTemplates: "/pcs/workflow/templates/solution/v1",
                getTemplatePackageById: "/pcs/workflow/template/v1/{templateId}/package",
                getTemplates: "/pcs/workflow/templates/v1",
                updateTemplate: "/pcs/workflow/template/v1/{templateId}",
                validateWorkflowForTemplate: "/pcs/workflow/template/v1/{workflowId}/action/validate",
            },
        },
        workflows: {
            v1: {
                archiveWorkflow: "/pcs/workflow/workflow/v1/{workflowId}/action/archive",
                copyWorkflow: "/pcs/workflow/workflow/v1/{workflowId}/action/copy",
                createWorkflow: "/pcs/workflow/workflow/v1",
                deleteWorkflow: "/pcs/workflow/workflow/v1/{workflowId}",
                getLaunchableWorkflows: "/pcs/workflow/workflows/v1/launchable",
                getPublishedWorkflowConfigurationPackageById: "/pcs/workflow/workflow/v1/{workflowId}/package",
                getWorkflows: "/pcs/workflow/workflows/v1",
                getWorkflowsById: "/pcs/workflow/workflow/v1/workflows",
                getWorkflowTypes: "/pcs/workflow/workflow/v1/workflowTypes",
                numberingPrefixCheck: "/pcs/workflow/workflow/v1/numberingPrefixCheck/{numberingPrefix}",
                publishWorkflow: "/pcs/workflow/workflow/v1/{workflowId}/action/publish",
                reviseWorkflow: "/pcs/workflow/workflow/v1/{workflowId}/action/revise",
                updateWorkflow: "/pcs/workflow/workflow/v1/{workflowId}",
                validateWorkflow: "/pcs/workflow/workflow/v1/{workflowId}/action/validate",
            },
            v2: {
                getLaunchableWorkflows: "/pcs/workflow/workflows/v2/launchable",
                getWorkflowConfigurationPackageById: "/pcs/workflow/workflow/v2/{workflowId}/package",
            },
        },
    },
    recordExecution: {
        actionItem: {
            createActionItem: "/pcs/record-execution/record/v1/action-item",
            getActionItemByRecord: "/pcs/record-execution/record/v1/{recordId}/action-item",
            getActionItemsByNodeId: "/pcs/record-execution/node/v1/{nodeId}/action-items",
        },
        approvals: {
            // FIXME: aqem BFF URIs should go into the AQEM shared folder apps\aqem\shared\src\uris.ts
            getApprovalData: "/api/aqem/approval/v1/node/{nodeId}",
        },
        attachment: {
            attachFile: "/pcs/record-execution/attachment/v1",
            getAttachmentOrUnattachFile: "/pcs/record-execution/attachment/v1/{attachmentId}",
        },
        correction: {
            getCorrectionAssignedToUser: "/pcs/record-execution/user/v1/{userId}/corrections",
            getCorrectionOrApproveRejectCorrection: "/pcs/record-execution/correction/v1/{correctionId}",
            getCorrectionsByTaskId: "/pcs/record-execution/task/v1/{taskId}/corrections",
            getUsersToSendCorrectionRequestOnNode: "/pcs/record-execution/node/v1/{nodeId}/users/correction",
            submitCorrectionV2: "/pcs/record-execution/correction/v2/action/request",
        },
        currentUserRecords: "/pcs/record-execution/user/v1/records",
        dueDateExtension: {
            approveExtension: "/pcs/record-execution/due-date-extension/v1/{dueDateExtensionId}/action/approve",
            createExtension: "/pcs/record-execution/due-date-extension/v1",
            denyExtension: "/pcs/record-execution/due-date-extension/v1/{dueDateExtensionId}/action/reject",
        },
        element: {
            completeUpdateElementCorrectionV2: "/pcs/record-execution/element/v2/{elementId}/correction",
            lockElement: "/pcs/record-execution/element/v1/{elementId}/action/lock",
            schema: "/pcs/record-execution/element/v1/{elementId}/schema",
            unlockElement: "/pcs/record-execution/element/v1/{elementId}/action/unlock",
            updateElement: "/pcs/record-execution/element/v1/{elementId}",
            updateElementCompleteCorrection: "/pcs/record-execution/element/v1/{elementId}/correction",
            updateElementCompleteNode: "/pcs/record-execution/element/v1/{elementId}/node/action/complete",
        },
        event: {
            pollForUpdates: "/pcs/record-execution/v1/updates",
        },
        node: {
            closeNode: "/pcs/record-execution/node/v1/{nodeId}/action/close",
            editUsersOnNode: "/pcs/record-execution/node/v2/user",
            getNodeApprovals: "/pcs/record-execution/node/v1/approvals",
            getNodeById: "/pcs/record-execution/node/v1/{nodeId}",
            getNodeParticipants: "/pcs/record-execution/node/v1/{nodeId}/participants",
            getRecordByNodeId: "/pcs/record-execution/node/v1/{nodeId}/record",
            unclaimNode: "/pcs/record-execution/node/v1/action/unclaim",
        },
        participant: {
            createParticipant: "/pcs/record-execution/participant/v1",
            getUserIds: "/pcs/record-execution/participant/v1/{participantId}/user-ids",
            reassignParticipant: "/pcs/record-execution/participant/v1/{participantId}/actor",
            removeParticipant: "/pcs/record-execution/participant/v1/{participantId}",
            replaceParticipant: "/pcs/record-execution/participant/v1/{participantId}/actor",
            updateParticipant: "/pcs/record-execution/participant/v1/{participantId}",
        },
        pdf: {
            retrieveCachedBlob: "/pcs/record-execution/pdf/v1/{cacheKey}/record",
        },
        record: {
            addToOrRemoveFromOrGetRecordCollection: "/pcs/record-execution/record/v1/{parentRecordId}/collection",
            cancelRecord: "/pcs/record-execution/record/v1/{recordId}/action/cancel",
            closeRecord: "/pcs/record-execution/record/v1/{recordId}/action/close",
            closeRecordV2: "/pcs/record-execution/record/v2/{recordId}/action/close",
            createRecord: "/pcs/record-execution/record/v1/action/create",
            createRecordV2: "/pcs/record-execution/record/v2/action/create",
            directCancel: "/pcs/record-execution/record/v1/{recordId}/action/directcancel",
            directClose: "/pcs/record-execution/record/v1/{recordId}/action/directclose",
            editRecord: "/pcs/record-execution/record/v2/{recordId}",
            editUsersOnRecord: "/pcs/record-execution/record/v1/user",
            getLocksWithinRecord: "/pcs/record-execution/record/v1/{recordId}/locks",
            getParticipantsOnRecord: "/pcs/record-execution/record/v1/{recordId}/participants",
            getRecordCollectionV2: "/pcs/record-execution/record/v2/{parentRecordId}/collection",
            getRecordDetailByRecordId: "/pcs/record-execution/record/v1/{recordId}/detail",
            getRecordDetailByRecordIdV2: "/pcs/record-execution/record/v2/{recordId}/detail",
            getRecords: "/pcs/record-execution/record/v1/records",
            getRecordsV2: "/pcs/record-execution/record/v2/records",
            getRecordsV3: "/pcs/record-execution/record/v3/records",
        },
        recordLink: {
            getLinkedRecordsByRecordId: "/pcs/record-execution/record/v1/{fromRecordId}/links",
            getRecordLinkById: "/pcs/record-execution/link/v1/{linkId}",
            getRecordLinkResponseByFromElementId: "/pcs/record-execution/element/v2/{fromElementId}/links",
            getRecordLinkResponseByRecordId: "/pcs/record-execution/record/v2/{fromRecordId}/links",
            getRecordsLinkedByFromElementId: "/pcs/record-execution/element/v1/{fromElementId}/links",
            linkCQxDocument: "/pcs/record-execution/link/v1/cqx",
            linkRecord: "/pcs/record-execution/link/v1",
        },
        row: {
            addRow: "/pcs/record-execution/row/v1",
            default: "/pcs/record-execution/row/v1/{rowId}",
        },
        settings: {
            editSettings: "/pcs/record-execution/v1/settings",
        },
        task: {
            claimTask: "/pcs/record-execution/task/v1/{taskId}/action/claim",
            closeTask: "/pcs/record-execution/task/v1/{taskId}",
            completeTask: "/pcs/record-execution/task/v1/{taskId}/action/complete",
            getCorrectionsByTaskId: "/pcs/record-execution/task/v1/{taskId}/corrections",
            getNextNodesV2: "/pcs/record-execution/task/v2/{taskId}/assignments/next",
            getRecordDetailV2: "/pcs/record-execution/task/v2/{taskId}/record/detail",
            getTask: "/pcs/record-execution/task/v1/{taskId}",
            getTaskFormElements: "/pcs/record-execution/task/v1/{taskId}/elements",
            getTasksOnNode: "/pcs/record-execution/node/v1/{nodeId}/tasks",
            getTasksOnRecord: "/pcs/record-execution/record/v1/{recordId}/tasks",
            getUserTasks: "/pcs/record-execution/task/v1/tasks",
            openNode: "/pcs/record-execution/node/v1/{nodeId}/action/open",
            signTask: "/pcs/record-execution/task/v1/{taskId}/action/sign",
        },
    },
    search: {
        search: "/pcs/search/v1/search",
        searchV2: "/pcs/search/v2/search",
        searchV3: "/pcs/search/v3/search",
    },
    signature: {
        createSignature: "/pcs/signature/signature/v1",
        getSignaturesByTargetIds: "/pcs/signature/signature/v1/target",
        invalidateSignatures: "/pcs/signature/signature/v1/invalidate",
    },
    tag: {
        createTag: "/pcs/tag/v1/tag",
        createTagAndLink: "/pcs/tag/v1/link/createTagAndLink",
        deleteTag: "/pcs/tag/v1/tag/{tagId}",
        fuzzySearch: "/pcs/tag/v1/tags/fuzzy-search",
        getLinks: "/pcs/tag/v1/links",
        getLinksByTagId: "/pcs/tag/v1/tag/{tagId}/links",
        getTag: "/pcs/tag/v1/tag/{tagId}",
        getTagsByTargetIdQuery: "/pcs/tag/v1/tags/target/{targetId}",
        searchTagsByQuery: "/pcs/tag/v1/tags/search",
        updateTag: "/pcs/tag/v1/tag/{tagId}",
    },
    validationOnDemand: {
        pcsReport: {
            getValidationPlanIdReportLanguage:
                "/pcs/validation-on-demand/pcs/validations/v1/{validationPlanId}/report/{language}",
        },
    },
};

export const ApiRegexUris = buildUriRegexCollection(ApiUris);
